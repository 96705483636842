import React, { useContext } from "react";
import Image1 from "../../assets/images/hotal-1.png";
import BadgeIcon from "../../assets/images/icons/aroplane-bed.svg";
import BadgeIconDark from "../../assets/images/icons/aroplane-bed-dark.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSearchFilters } from "../../redux/slices/searchFilterSlice";
import {
  getCurrencyBadge,
  getListingItemFilters,
  handleFavoriteHotelItem,
  navigateToDetailPage,
} from "../../helpers/common";
import { getAppConfig } from "../../redux/slices/appconfigSlice";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import { useTranslation } from "react-i18next";
import AppConfig from "../../helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faMapMarkerAlt,
  faStar,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import FavoriteAccordion from "../FavoriteAccordion";
import PropTypes from "prop-types";

const HotelItem = ({ offer, index, handler = null }) => {
  const { t } = useTranslation();
  const appconfig = useSelector(getAppConfig);
  const loginconfig = useSelector(getLoginConfig);
  const searchfilters = useSelector(getSearchFilters);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currency = "$";
  const filters = getListingItemFilters(offer, t, offer?.objHotel?.newPackagedOffer?.flightOffer);

  if (offer?.detailsLink) {
    const objUrl = new URL(offer?.detailsLink);
    const searchparams = new URLSearchParams(objUrl.search);
    currency = searchparams.get("currency");
  }

  function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button type="button" className={"action-btn"} onClick={decoratedOnClick}>
        {isCurrentEventKey ? t("Buttons.ViewLess") : t("Buttons.ViewMore")}{" "}
        <FontAwesomeIcon
          icon={isCurrentEventKey ? faChevronUp : faChevronDown}
        />
      </button>
    );
  }

  ContextAwareToggle.propTypes = {
    eventKey: PropTypes.string.isRequired,
    callback: PropTypes.func,
  };

  return (
    <>
      <Accordion>
        <div className="small-package-card">
          <div className="small-card-image-holder">
            <img
              src={offer?.thumbnailUrl || offer?.hotelImg || Image1}
              className="covered-image"
              alt={"hotel-image"}
              onClick={() =>
                !offer?.detailsLink &&
                navigateToDetailPage(
                  offer,
                  dispatch,
                  loginconfig,
                  searchfilters,
                  navigate,
                  "hotel"
                )
              }
            />
            <a
              className="fav-icon"
              onClick={() => {
                handleFavoriteHotelItem(
                  loginconfig,
                  dispatch,
                  searchfilters,
                  offer,
                  appconfig,
                  index,
                  null,
                  offer?.objHotel?.newPackagedOffer?.flightOffer
                );
                if (handler) {
                  handler(index);
                }
              }}
            >
              <FontAwesomeIcon
                icon={faHeart}
                className={offer?.favoriteGuid ? "favorite" : ""}
              />
            </a>
            <div className="card-badge">
              <img src={BadgeIcon} alt={"badge-icon"} />
              <span>{t("HotelInclFlight")}</span>
            </div>
          </div>
          <div className="small-card-content-holder">
            <div className="reviews">
              <div className="star">
                {[1, 2, 3, 4, 5].map((item, index) => {
                  if (item <= Number(offer?.starRating))
                    return (
                      <FontAwesomeIcon
                        key={index}
                        icon={faStar}
                        className="filled"
                      />
                    );
                  else
                    return (
                      <FontAwesomeIcon
                        key={index}
                        icon={faStar}
                        className="blank"
                      />
                    );
                })}
              </div>
            </div>
            <h4 className="heading heading-6 dark-text font-poppings ellipis">
              {offer?.name || offer?.hotel}
            </h4>
            <div className="reviews">
              <div className="star">
                <span>{`${t("Rating")}: ${offer?.guestRating || 0} (${t("Reviews")} ${offer?.guestReviewCount || 0})`}</span>
              </div>
            </div>
            <p className="location">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {offer?.address || offer?.location}
            </p>
            <ul className="info-tags">
              {filters?.map((filter, index) => {
                return (
                  <li
                    className="veryLight-bg fs-12 medium darkgray-color"
                    key={index}
                  >
                    {filter.title}
                  </li>
                );
              })}
            </ul>
            <hr className="seperator"></hr>
            <div className="logo-holder">
              {offer?.objHotel?.offerType === "dailytop10" && (
                <div className="daily-offer-list-small">{t("dailyOffer")}</div>
              )}
              <img
                src={AppConfig.getAirlineImageURL(
                  offer?.objHotel?.newPackagedOffer?.flightOffer?.segments[0]
                    ?.legs[0]?.marketingAirlineCode
                )}
                alt={"card-logo"}
              />
              <p>
                <img src={BadgeIconDark} alt={"badge-icon"} />{" "}
                {t("HotelInclFlight").toLowerCase()}
              </p>
            </div>
            <div className="price-holder">
              <div className="price">
                {getCurrencyBadge(offer?.perPersonPrice?.currency || currency)}
                {offer?.price && (
                  <PriceBoxes
                    price={Number(
                      offer?.perPersonPrice?.totalPrice || offer?.price
                    ).toFixed(0)}
                  />
                )}
              </div>
              <div className="action">
                {!offer?.detailsLink ? (
                  <a
                    className="action-btn"
                    onClick={() =>
                      !offer?.detailsLink &&
                      navigateToDetailPage(
                        offer,
                        dispatch,
                        loginconfig,
                        searchfilters,
                        navigate,
                        "hotel"
                      )
                    }
                  >
                    {t("Buttons.View")}
                  </a>
                ) : (
                  <ContextAwareToggle eventKey={index}></ContextAwareToggle>
                )}
              </div>
            </div>
            <p className="price-caption">{t("totalp.p")}</p>
          </div>
        </div>
        <Accordion.Collapse eventKey={index}>
          <FavoriteAccordion offer={offer}/>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

const PriceBoxes = ({ price }) => {
  let value = price.toString().split("");
  let valueIndex = 0;
  let _price = [];
  for (let index = 0; index <= value.length; index++) {
    if (value.length && value[value.length - valueIndex]) {
      _price.unshift(value[value.length - valueIndex]);
    }
    valueIndex++;
  }

  return _price.map((digit, index) => <span key={index}>{digit}</span>);
};

PriceBoxes.propTypes = {
  price: PropTypes.string,
};

HotelItem.propTypes = {
  offer: PropTypes.object,
  index: PropTypes.number,
  handler: PropTypes.func,
};

export default HotelItem;
