import { post } from "./HttpClient";
import { DELETE_FAVORITES, INSERT_FAVORITES } from "./ApisRoutes";

export const AddToFavoriteList = (params) => {
  return new Promise((resolve, reject) => {
    post(INSERT_FAVORITES, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const RemoveFromFavoriteList = (params) => {
  // console.log("PARAMS", params);
  return new Promise((resolve, reject) => {
    post(DELETE_FAVORITES, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    // resolve();
  });
};
