import React from 'react';
import {useEffect, useState} from "react";
import "./style.scss";
import PropTypes from "prop-types";

const AnimatedCard = ({animation, digit}) => {
    return (
        <div className={`flipCard ${animation}`}>
            <span>{digit}</span>
        </div>
    );
};

AnimatedCard.propTypes = {
    animation: PropTypes.any,
    digit: PropTypes.any
}

// function component
const StaticCard = ({position, digit}) => {
    return (
        <div className={position}>
            <span>{digit}</span>
        </div>
    );
};

StaticCard.propTypes = {
    position: PropTypes.any,
    digit: PropTypes.any
}

const FlipUnitContainer = ({digit, previousNumber, shuffle, index}) => {
    // assign digit values
    let currentDigit = digit;
    let previousDigit = previousNumber;

    const [digit1, setDigit1] = useState();
    const [digit2, setDigit2] = useState();
    const [animation1, setAnimation1] = useState();
    const [animation2, setAnimation2] = useState();

    useEffect(() => {
        setTimeout(() => {
            // shuffle digits
            setDigit1(shuffle ? previousDigit : currentDigit);
            setDigit2(!shuffle ? previousDigit : currentDigit)

            setAnimation1(shuffle ? 'fold' : 'unfold');
            setAnimation2(!shuffle ? 'fold' : 'unfold');

        }, 150 + 150 * index);
    }, [currentDigit, previousDigit, shuffle, index]);

    return (
        <div className={'flipUnitContainer'}>
            <StaticCard
                position={'upperCard'}
                digit={currentDigit}
            />
            <StaticCard
                position={'lowerCard'}
                digit={previousDigit}
            />
            <AnimatedCard
                digit={digit1}
                animation={animation1}
            />
            <AnimatedCard
                digit={digit2}
                animation={animation2}
            />
        </div>
    );
};

FlipUnitContainer.propTypes = {
    digit: PropTypes.any,
    previousNumber: PropTypes.any,
    shuffle: PropTypes.bool,
    index: PropTypes.number
}

export default React.memo(FlipUnitContainer);
