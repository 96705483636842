import React from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import BlogPageCardSection from "../../components/sections/BlogPageCards/BlogPageCards";

const Blog = () => {
  return (
    <div className="main-wrapper blog">
      <AppHeader />
      <main className="body-wrapper">
        <BlogPageCardSection />
      </main>
      <Footer />
    </div>
  );
};

export default Blog;