import React from "react";
import HotelItem from "../HotelItem/GridViewCArd";
import Slider from "react-slick";
import "./style.scss";
import { useSelector } from "react-redux";
import { getAppConfig } from "../../redux/slices/appconfigSlice";
import PropTypes from "prop-types";

const GridView = () => {
  const appconfig = useSelector(getAppConfig);

  function SampleNextArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
            onClick={onClick}
        ></span>
    );
  }

  function SamplePrevArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
            onClick={onClick}
        ></span>
    );
  }

  SampleNextArrow.propTypes = {
    onClick: PropTypes.func,
  }

  SamplePrevArrow.propTypes = {
    onClick: PropTypes.func,
  }

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: true,
    centerPadding: "150px",
    slidesToShow: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 700,
    arrows: true,
    responsive: [
      {
        breakpoint: 1530,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
        },
      },
    ],
  };
  return (
    <>
      <div className="row for-desktop">
        {appconfig?.offers?.map(
          (offer, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6 col-md-6">
                <HotelItem offer={offer} index={index} />
              </div>
            )
          })}
      </div>
      <div className="hotal-items-slider for-mobile">
        <Slider {...settings}>
          {appconfig?.offers?.map(
            (offer, index) => {
              return (
                <div key={index} className="col-xl-4 col-lg-6 col-md-6">
                  <HotelItem offer={offer} index={index} />
                </div>
              )
            })}
        </Slider>
      </div>
    </>
  );
};

export default GridView;
