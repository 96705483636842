import React, {useEffect, useState} from "react";
import AppHeader from "../../components/header";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import RoomContent from "../../components/sections/RoomContent/RoomContent";
import DetailImages from "../../components/sections/DetailsImage/DetailsImage";
import TextSection from "../../components/sections/BottomTextSetion/BottomTextSetion";
import Footer from "../../components/footer";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getSearchFilters} from "../../redux/slices/searchFilterSlice";
import {useNavigate} from "react-router-dom";
import {getCurrencyBadge, getFlightsParams, getThreeFilterToShow, moveNumberToEnd, navigateToDetailPage} from "../../helpers/common";
import PriceFlipper from "../../components/PriceFlipper";
import BedFlightIcon from "../../assets/images/icons/bedflight-icon.svg";
import {getBooking, setHotelRoom} from "../../redux/slices/bookingSlice";
import RoomsSlider from "../../components/sections/RoomCardSlider/RoomsSlider";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {getAppConfig, setActivityIndicator} from "../../redux/slices/appconfigSlice";
import {GetFlightDetailsService} from "../../services/HotelDetailServices";
import {toast} from "react-toastify";

const RoomDeatils = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const url = window.location.href;
    const searchfilters = useSelector(getSearchFilters);
    const appconfig = useSelector(getAppConfig);
    const loginconfig = useSelector(getLoginConfig);
    const booking = useSelector(getBooking);
    const [room, setRoom] = useState(null);
    const [flight, setFlight] = useState(null);
    const [oldFlight, setOldFlight] = useState(null);
    const [justArrived, setJustArrived] = useState(true);
    const count = room?.ratePlans[0]?.remainingCount || 0;
    let filters = getThreeFilterToShow(room, t, flight);

    const getPrice = price => Number(price?.value).toFixed(0) || 0;
    const [price, setPrice] = useState(
        getPrice(flight?.price?.packageTotalPrice),
    );

    useEffect(() => {
        setPrice(getPrice(flight?.price?.packageTotalPrice));
    }, [flight]);

    useEffect(() => {
        if (!justArrived) {
            navigate('/');
        } else {
            setJustArrived(false);
        }
    }, [loginconfig.locale, loginconfig.currency]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const params = getQueryParamsFromURL();
        const index = searchfilters.selectedHotelDetail?.findIndex(
            (f) => f?.roomKey === params?.roomKey
        );
        if (index !== -1) {
            setRoom(searchfilters.selectedHotelDetail[index]);
        } else {
            navigate(-1);
        }
    }, []);

    useEffect(() => {
        setOldFlight(flight ? flight : booking?.flight);
        setFlight(booking?.flight);
    }, []);

    const getQueryParamsFromURL = () => {
        const objUrl = new URL(url);
        const searchparams = new URLSearchParams(objUrl.search);
        return {
            roomKey: searchparams.get("roomKey"),
            GUID: searchparams.get("GUID"),
        };
    };

    const handleOnBooking = () => {
        const params = getFlightsParams(
            room,
            searchfilters,
            loginconfig,
            booking,
            appconfig,
            searchfilters?.selectedHotel?.id,
            searchfilters?.selectedHotel?.guidForBooking
        );
        params.GetFinalExpediaUrl = true;
        params.transaction_id = appconfig?.transaction_id;

        // console.log(JSON.stringify(params), "params for getting more flights");

        dispatch(setActivityIndicator(true));
        GetFlightDetailsService(params)
            .then((_flightDetails) => {
                dispatch(setActivityIndicator(false));

                // console.log({_flightDetails});

                if (
                    _flightDetails?.warnings?.length &&
                    _flightDetails?.warnings[0]?.code === "400.19"
                ) {
                    toast.info(_flightDetails?.warnings[0]?.description);
                    return;
                }

                window.open(
                    _flightDetails?.offers[0]?.links?.webDetails?.href,
                    "_blank"
                );
            })
            .catch(() => {
                dispatch(setActivityIndicator(false));
            });
    };


    return (
        <div className="main-wrapper flight-page">
            <AppHeader/>
            <main className="body-wrapper">
                <Topbar/>
                <DetailImages images={room?.media} type="room"/>
                <div id="RomContent" className="room-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                {room && <RoomContent room={room}/>}
                            </div>
                            <div className="col-lg-4">
                                <div className="room-pricing-holder">
                                    <div className="room-pricing-action">
                                        <a onClick={() => {
                                            dispatch(setHotelRoom(room));
                                            setTimeout(() => {
                                                navigateToDetailPage(
                                                    booking?.hotel,
                                                    dispatch,
                                                    loginconfig,
                                                    searchfilters,
                                                    navigate,
                                                    "hotel",
                                                    false,
                                                    false,
                                                    true
                                                );
                                            }, 100);
                                        }}>{t("ChooseRoomAndFlights")}</a>
                                        <p>
                                            {t("WeHave")} <span className="count">{count}</span>{" "}
                                            {count > 0 ? t("rooms") : t("room")} {t("remaining")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="detailsContent">
                            <div className="col-lg-12">
                                <div className="feat-detail-wrapper content-holder">
                                    <div className="feat-detail-holder">
                                        <ul className="features-deatils row">
                                            <h6>{t("Amenities")}</h6>
                                            {room?.amenities.map((amenity, index) => {
                                                return (
                                                    <li className="col-md-4" key={index}>
                                                        {moveNumberToEnd(amenity?.name)}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <RoomsSlider
                                    setRoom={setRoom}
                                    oldFlight={flight}
                                    selectedRoom={room}
                                    priceChangeHandler={(newFlight) => {
                                        setOldFlight(flight);
                                        setFlight(newFlight);
                                    }}
                                />
                            </div>

                            <div className="desktop-price-section">
                                <div className="container">
                                    <div className="bluebox-bg">
                                        <div className="tags-total">
                                            <div>
                                                <div>
                                                    {filters?.map((f, i) => (
                                                        <div key={i} className="badge">
                                                            {f?.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="average-price">
                                                {t("AveragePP")}
                                                <div className="d-flex">
                                                    <div className="currency">
                                                        {getCurrencyBadge(
                                                            flight?.price?.averageCostPerPerson?.currency,
                                                        )}
                                                    </div>
                                                    <div className="amount">
                                                        {Number(flight?.price?.averageCostPerPerson?.value).toFixed(
                                                            2,
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="price-info-holder">
                                            <div className="price">
                                                <div className="d-flex align-items-center gap-2">
                                                    {getCurrencyBadge(flight?.price?.packageTotalPrice?.currency)}

                                                    <PriceFlipper
                                                        price={price}
                                                        oldPrice={getPrice(oldFlight?.price?.packageTotalPrice)}
                                                    />
                                                </div>
                                                <div className="floater-total-taxes">
                                                    {Number(flight?.price?.savings?.value) > 0 && (
                                                        <div className="total">
                                                            {t("Total")}{" "}
                                                            <em>
                                                                {getPrice(flight?.price?.standAloneTotalPrice)}
                                                            </em>
                                                        </div>
                                                    )}

                                                    {flight?.price?.hotelMandatoryFees && (
                                                        <p className="text-white">{`+${getCurrencyBadge(
                                                            flight?.price?.hotelMandatoryFees?.currency
                                                        )} ${Number(flight?.price?.hotelMandatoryFees?.value).toFixed(
                                                            2
                                                        )} ${t("LocalText")}`}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="action">
                                                <a onClick={handleOnBooking}>
                                                    {t("DetailCard.IWantThisDeal")}
                                                    <img
                                                        src={BedFlightIcon}
                                                        className="bed-icon"
                                                        alt={"bed-icon"}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <TextSection/>
            </main>
            <Footer/>
        </div>
    );
};

export default RoomDeatils;
