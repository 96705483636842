const BASE_API_URL = "https://production-geniustravel.cherimoya.be/v1"; //Prod
// const BASE_API_URL = "https://staging-geniustravel.cherimoya.be/v1"; //Staging
// const BASE_API_URL = "https://develop-geniustravel.cherimoya.be/v1"; //Dev

const GOOGLE_MAPS_API_KEY = 'AIzaSyBM0rIE-Er4AXCP3A8EA9eh7rCPewyzlI8'; // Dev
// const GOOGLE_MAPS_API_KEY = 'AIzaSyBDoROgPNgvDNdLAZXeL6PCrzgEndGnOgE'; // Production

const BRANCH_KEY ='key_live_kwaumCX3opOWV4Uzg8gJYlbaqCc7kAOU';
// const BRANCH_KEY = 'key_test_nsaDcFW9ecK1K7Krl6kL3odhErm1azS8';

const CURRENT_VERSION = '4.4.10';

const AIRLINE_BRAND_IMAGE = (countryCode) =>
    countryCode &&
    `https://geniustravel-prod.azureedge.net/Airlines/th_${countryCode?.toLowerCase()}_tx.png`;

const MIN_PRICE = 0;
const MAX_PRICE = 2500;

const _TILE_VALUES = (mini, maxi) => {
    const min = Number(mini);
    const max = Number(maxi);

    let wholeNumber = 0,
        diff = 0;

    if (min !== 0) {
        diff = ((max - min) / 20).toFixed(0);
    } else {
        wholeNumber = (max / 20).toFixed(0);
    }

    return [
        {height: 5},
        {height: 5},
        {height: 12},
        {height: 10},
        {height: 13},
        {height: 15},
        {height: 21},
        {height: 31},
        {height: 33},
        {height: 33},
        {height: 31},
        {height: 28},
        {height: 30},
        {height: 35},
        {height: 40},
        {height: 49},
        {height: 45},
        {height: 35},
        {height: 10},
        {height: 5},
    ].map((tile, index) => {
        return {
            ...tile,
            value:
                min === 0
                    ? wholeNumber * (index + 1)
                    : Number((min + diff * index).toFixed()),
        };
    });
};

const _ROOM_EXTRA_SERVICE = [
    {id: 1, service: "No extras", price: "+ €0", isChecked: false},
    {id: 2, service: "Breakfast", price: "+ €10", isChecked: false},
];

const CURRENCIES = [
    {code: "EUR", name: "Euro", symbol: "€"},
    {code: "USD", name: "Dollar", symbol: "$"},
    // {code: "GBP", name: "Pound", symbol: "£"},
];

const _APP_LANGUAGES = [
    {
        id: 0,
        country: "Nederland",
        code: "nl_NL",
        name: "Dutch",
        short: "Nl",
    },
    {
        id: 1,
        country: "Engels",
        code: "en_US",
        name: "English (US)",
        short: "En",
    },
    // {
    //     id: 2,
    //     country: "United Kingdom",
    //     code: "en_GB",
    //     name: "English (UK)",
    //     short: "En",
    // },
];

const _WebClient =
    "248848665514-uvpfqs6n27tahvahemcnhatkn4lnaha9.apps.googleusercontent.com";

// const _POPULAR_FILTERS = [
//     {
//         id: 1,
//         text: "Free Internet",
//         key: "popular_filters",
//         value: "FreeInternet",
//     },
//     {id: 2, text: "Free Parking", key: "popular_filters", value: "FreeParking"},
// ];

const _DEPARTIRE_DATE = [
    {id: 1, text: "± 1 days", key: "departure_date"},
    {id: 2, text: "± 2 days", key: "departure_date"},
    {id: 3, text: "± 3 days", key: "departure_date"},
];

const _GUEST_RATING = [
    {id: 1, key: "guest_rating", text: "⭐", icon: true},
    {id: 2, key: "guest_rating", text: "⭐ ⭐", icon: true},
    {id: 3, key: "guest_rating", text: "⭐ ⭐ ⭐", icon: true},
    {id: 4, key: "guest_rating", text: "⭐ ⭐ ⭐ ⭐", icon: true},
    {id: 5, key: "guest_rating", text: "⭐ ⭐ ⭐ ⭐ ⭐", icon: true},
];

const _STEPS = [
    {id: 0, key: "steps", text: "Direct"},
    {id: 1, key: "steps", text: "1 stop"},
    {id: 2, key: "steps", text: "2+ stops"},
];

const _DEPARTURE_TIME = [
    {id: 1, key: "departure_time", text: "Anytime"},
    {
        id: 2,
        key: "departure_time",
        text: "Morning (06:30 - 12:00)",
        StartTime: "06:30:00",
        EndTime: "12:00:00",
    },
    {
        id: 3,
        key: "departure_time",
        text: "Afternoon (12:00 - 18:00)",
        StartTime: "12:00:00",
        EndTime: "18:00:00",
    },
    {
        id: 4,
        key: "departure_time",
        text: "Night (18:00 - 00:00)",
        StartTime: "18:00:00",
        EndTime: "00:00:00",
    },
];

const _ARRIVAL_TIME = [
    {id: 1, key: "arrival_time", text: "Anytime"},
    {
        id: 2,
        key: "arrival_time",
        text: "Morning (06:30 - 12:00)",
        StartTime: "06:30:00",
        EndTime: "12:00:00",
    },
    {
        id: 3,
        key: "arrival_time",
        text: "Afternoon (12:00 - 18:00)",
        StartTime: "12:00:00",
        EndTime: "18:00:00",
    },
    {
        id: 4,
        key: "arrival_time",
        text: "Night (18:00 - 00:00)",
        StartTime: "18:00:00",
        EndTime: "00:00:00",
    },
];

const _AIRLINE_CLASSES = [
    {id: 1, key: "airline_class", text: "ECONOMY", value: "ECONOMY"},
    {id: 2, key: "airline_class", text: "BUSINESS", value: "BUSINESS"},
    {
        id: 3,
        key: "airline_class",
        text: "PREMIUM ECONOMY",
        value: "PREMIUMECONOMY",
    },
    {id: 4, key: "airline_class", text: "FIRST", value: "FIRST"},
];

const AppConfig = {
    baseApiURL: BASE_API_URL,
    branchKey: BRANCH_KEY,
    googleMapAPIKey: GOOGLE_MAPS_API_KEY,
    webVersion: CURRENT_VERSION,
    appLanguages: _APP_LANGUAGES,
    tileValues: _TILE_VALUES,
    fakeRoomExtraService: _ROOM_EXTRA_SERVICE,
    webClient: _WebClient,
    // popularFilters: _POPULAR_FILTERS,
    departureDate: _DEPARTIRE_DATE,
    guestRating: _GUEST_RATING,
    departureTime: _DEPARTURE_TIME,
    arrivalTime: _ARRIVAL_TIME,
    steps: _STEPS,
    airlineClasses: _AIRLINE_CLASSES,
    minPrice: MIN_PRICE,
    maxPrice: MAX_PRICE,
    getAirlineImageURL: AIRLINE_BRAND_IMAGE,
    currencies: CURRENCIES,
};

export default AppConfig;
