import React, {useEffect} from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";

const TermsConditions = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const loginconfig = useSelector(getLoginConfig);

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <div className="container">
                    <AppHeader/>
                    {loginconfig?.locale?.code === 'en_US' ? (
                        <main className="body-wrapper">
                            <div className="left-action-back-btn">
                                <a onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                            </div>
                            <h2 className="text-center">{t("AppFooter.TermsAndConditions")}</h2>

                            <h5>Genius AI</h5>
                            <p>
                                If you use the Genius AI Travel Advisor: Our Genius will of course do its best to
                                provide you with the best possible (travel) advice. Genius&lsquo;s goal is also to
                                provide you
                                with the most appropriate travel or travel advice possible. But... Humans make mistakes
                                too, so that can happen to our Genius AI in the advice Genius gives you. If advice given
                                by Genius is incorrect, it should not lead to a complaint in any form. No refunds will
                                be given if, for example, Genius mentions a price that turns out to be incorrect. Of
                                course, Genius does its best to inform you as correctly as possible, a wrong price or
                                wrong information will never happen intentionally. Of course, you can send us a message
                                about this (please do) so that we know how to improve our Genius further. And
                                fortunately, our Genius is open to criticism and self-reflection! 😉
                            </p>
                            <h5>Booking the trip</h5>
                            <p>
                                If you book a trip, it is done via our partner Expedia, you book with Expedia and pay
                                with Expedia. By booking the trip, you then agree to Expedia&lsquo;s terms and
                                conditions,
                                which can be found at:
                            </p>
                            <a rel="noreferrer" href={'https://www.expedia.co.uk/lp/b/terms-of-service'}
                               target={'_blank'}>https://www.expedia.co.uk/lp/b/terms-of-service</a>
                            <p>Only Expedia has your travel information, there is no point in sending us a message about
                                it, as we do not have access to the travel details. You can contact Expedia at:</p>
                            <a rel="noreferrer" href={'https://www.expedia.co.uk/service/'}
                               target={'_blank'}>https://www.expedia.co.uk/service/</a>
                            <p>
                                By using Genius to search for your trip, you agree to the Terms and Conditions of
                                GeniusTravel B.V., when you book a trip, you agree to the terms and conditions of
                                Expedia. We wish you a lot of searching pleasure in finding a fantastic trip and of
                                course wish you a pleasant trip!
                            </p>
                        </main>
                    ) : (
                        <main className="body-wrapper">
                            <div className="left-action-back-btn">
                                <a onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                            </div>
                            <h2 className="text-center"> {t("AppFooter.TermsAndConditions")}</h2>

                            <h5>Genius AI</h5>
                            <p>
                                Als u gebruikmaakt van de Genius AI Reisadviseur: Onze Genius gaat uiteraard zijn best
                                doen om u te voorzien van een zo goed mogelijk (reis)advies. Genius zijn doel is ook u
                                te voorzien van een zo passend mogelijke reis of reisadvies. Maar... ook mensen maken
                                fouten, dus dat kan ook onze Genius AI overkomen in het advies dat Genius u geeft. Mocht
                                een advies gegeven door Genius niet correct zijn dan mag dat niet leiden tot een
                                aanklacht in wat voor vorm dan ook. Er wordt ook geen restitutie verleend mocht Genius
                                bijvoorbeeld een prijs noemen die niet kloppend blijkt te zijn. Uiteraard doet Genius
                                zijn best u zo correct mogelijk te informeren, een verkeerde prijs of verkeerde
                                informatie zal nooit opzettelijk gebeuren. U mag ons daarover uiteraard wel een bericht
                                sturen (graag zelfs) zodat wij weten wat onze Genius verder zou kunnen verbeteren. En
                                gelukkig staat onze Genius open voor kritiek en zelfreflectie! 😉
                            </p>
                            <h5>Het boeken van de reis</h5>
                            <p>
                                Mocht u een reis boeken dan gebeurt dat vervolgens via onze partner Expedia, u boekt bij
                                Expedia en betaalt bij Expedia. Door de reis te boeken stemt u vervolgens in met de
                                algemene voorwaarden van Expedia, deze zijn te vinden via:
                            </p>
                            <a rel="noreferrer" href={'https://www.expedia.co.uk/lp/b/terms-of-service'}
                               target={'_blank'}>https://www.expedia.co.uk/lp/b/terms-of-service</a>
                            <p>Alleen Expedia heeft uw reisinformatie, ons daarover een bericht sturen heeft geen zin,
                                aangezien wij niet de beschikking hebben over de reisgegevens. Contact zoeken met
                                Expedia kan via:</p>
                            <a rel="noreferrer" href={'https://www.expedia.co.uk/service/'}
                               target={'_blank'}>https://www.expedia.co.uk/service/</a>
                            <p>
                                Door Genius te gebruiken voor het zoeken van uw reis stemt u in met de Algemene
                                Voorwaarden van GeniusTravel B.V., als u een reis boekt stemt u in met de algemene
                                voorwaarden van Expedia. Wij wensen u veel zoekplezier toe bij het vinden van een
                                fantastische reis en wensen u uiteraard een prettige reis toe!
                            </p>
                        </main>
                    )}
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default TermsConditions;
