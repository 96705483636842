import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import EyeIcon from "../../assets/images/icons/eye_on.svg";
import EyeIconClosed from "../../assets/images/icons/eye_off.svg";
import "./style.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import SocialLogins from "../SocialLogins";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";
import {LoginService} from "../../services/LoginService";
import {setIsLogin, setLoginUser} from "../../redux/slices/loginconfigSlice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

const SignIpModal = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [inputType, setInputType] = useState(false);

    useEffect(() => {
        if (!props.show) {
            setEmail("");
            setPassword("");
        }
    }, [props.show]);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(setActivityIndicator(true));
        LoginService({Email: email, Password: password})
            .then((_loginResponse) => {
                dispatch(setLoginUser(_loginResponse));
                dispatch(setIsLogin(true));
                dispatch(setActivityIndicator(false));
                props.onHide();
            })
            .catch((error) => {
                console.log({error});
                dispatch(setActivityIndicator(false));
                if (error?.response?.status === 400) {
                    toast.error(
                        error?.response?.data?.errors[0]?.description?.toString()
                    );
                }
            });
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="fs-38 medium">{t("SignIn")}</h4>
                <form className="auth-form login" onSubmit={handleLogin}>
                    <div className="form-group">
                        <input
                            value={email}
                            type="email"
                            placeholder={t("Email")}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            value={password}
                            type={inputType ? "text" : "password"}
                            placeholder={t("Password")}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                        />
                        <img
                            className="icon"
                            src={inputType ? EyeIconClosed : EyeIcon}
                            onClick={() => setInputType(!inputType)}
                            alt={"icon"}
                        />
                    </div>
                    <div className="login-btn">
                        <button type="submit">{t("Login")}</button>
                    </div>

                    <SocialLogins/>

                    <a
                        className="fs-12 font-noto"
                        onClick={() => {
                            props.onHide();
                            props.showForgotModal(true);
                        }}
                    >
                        {t("Modals.ForgotPassword")}
                    </a>
                </form>
            </Modal.Body>
        </Modal>
    );
};

SignIpModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    showForgotModal: PropTypes.func,
};

export default SignIpModal;
