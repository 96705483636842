import { get } from "./HttpClient";
import { API_HOME_PACKAGE_TYPES } from "./ApisRoutes";
import { getTravelersData } from "../helpers/common";

export const GetPackageTypesService = (searchFilters, loginConfig) => {
  const { adults, infants, childs } = getTravelersData(searchFilters);

  const count = adults + infants + childs;

  let API_URL = API_HOME_PACKAGE_TYPES;
  if (count > 0) {
    API_URL = API_HOME_PACKAGE_TYPES + "?numberOfTravellers=" + count;
  }
  API_URL =
    API_URL +
    "&locale=" +
    loginConfig?.locale?.code +
    "&currency=" +
    loginConfig?.currency?.code;
  return new Promise((resolve, reject) => {
    console.log({API_URL})
    get(API_URL)
      .then((response) => {
        resolve(response?.data?.packageTypes);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
