import React from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchFilters,
  setSelectedPackage,
} from "../../redux/slices/searchFilterSlice";
import { useNavigate } from "react-router-dom";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import PropTypes from "prop-types";

const PackageCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchfilters = useSelector(getSearchFilters);
  const loginConfig = useSelector(getLoginConfig);

  let up = false;
  let down = false;
  let move = false;

  const navigateToSearchPage = (_package) => {
    if (down && !move && up) {
      dispatch(setSelectedPackage(_package));
      navigate(
        "/search/" +
          _package.guid +
          "/query?page=1" +
          "&locale=" +
          loginConfig.locale.code +
          "&currency=" +
          loginConfig?.currency?.code
      );
    }
  };

  return (
    <>
      <div
        className={`globe-card ${
          props?.package?.guid === searchfilters?.selectedPackage?.guid
            ? "active"
            : ""
        }`}
        onMouseDown={() => {
          up = false;
          down = true;
          move = false;
        }}
        onMouseUp={() => {
          up = true;
          navigateToSearchPage(props?.package);
          move = false;
          down = false;
        }}
        onMouseMove={() => {
          move = true;
        }}
      >
        <div className="globe-card-header">
          <img src={props?.package?.imgUrl} alt={"package"} />
          <div className="text">
            <h6 className="white-text medium">{props?.package?.type?.replace('*', ' ⭐')}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

PackageCard.propTypes = {
  package: PropTypes.object,
}

export default PackageCard;
