import React, {useState} from "react";
import ShareIcon from "../../../assets/images/icons/icon-share.svg";
import HeartIcon from "../../../assets/images/icons/icon-heart.svg";
import HeartFilledIcon from "../../../assets/images/icons/icon-heart-filled.svg";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./style.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAppConfig} from "../../../redux/slices/appconfigSlice";
import {getLoginConfig} from "../../../redux/slices/loginconfigSlice";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import {handleFavoriteHotelItem} from "../../../helpers/common";
import {useTranslation} from "react-i18next";
import {getBooking} from "../../../redux/slices/bookingSlice";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import branch from "branch-sdk";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import MapJson from '../../../assets/images/lottieViews/map.json';
import AppConfig from "../../../helpers/config";

const TopBar = ({isMap = false}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {selectedHotel} = useSelector(getSearchFilters);
    const appconfig = useSelector(getAppConfig);
    const loginconfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const booking = useSelector(getBooking);
    const [shortLink, setShortLink] = useState("");

    const createLink = () => {

        const getURL = AppConfig.baseApiURL.includes('staging')
            ? 'https://staging.genius.travel/'
            : 'https://genius.travel/';


        const linkData = {
            campaign: 'genius/package',
            data: {
                payload: JSON.stringify(selectedHotel),
                '$ios_url': getURL,
                '$ios_url_xx': getURL,
                '$ipad_url': getURL,

                '$android_url': getURL,
                '$android_url_xx': getURL,
                '$android_deeplink_path': 'geniustravel://',

                '$og_title': selectedHotel?.name,
                '$og_description': `★ ${selectedHotel?.starRating} • ${selectedHotel?.address}`,
                '$og_image_url': selectedHotel?.thumbnailUrl
            }
        };

        branch.link(linkData, function (err, link) {
            // console.log(link);
            setShortLink(link);
        });
    }

    return (
        <div id="topBar" className="topbar-wrapper">
            <div className="container">
                <div className={isMap? '': 'content-holder'}>
                    <div className="left-action">
                        <a style={{color: isMap? 'white': 'white', background: isMap? '#00000082': ''}} onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                    </div>
                    {!isMap && <div className="right-action">
                        <ul>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    className="show-map-link"
                                    // href={`https://maps.google.com/?q=${selectedHotel?.location?.geoLocation?.latitude},${selectedHotel?.location?.geoLocation?.longitude}`}
                                    // target="_blank"
                                    onClick={() => navigate(`/hotel/${selectedHotel?.id}/map`)}
                                >
                                    <Lottie animationData={MapJson} loop={true} className='map_animation'/>
                                    <span>{t("TopBar.ShowOn")}{" "}{t("TopBar.Map")}</span>
                                </a>
                            </li>
                            <li>
                                <Dropdown className="share-button" onToggle={createLink}>
                                    <Dropdown.Toggle>
                                        <img src={ShareIcon} alt={"share-icon"}/>
                                        {t("Buttons.Share")}
                                    </Dropdown.Toggle>
                                    {shortLink &&
                                        <Dropdown.Menu>
                                            <h6>{t("CopyToClipBoard")}</h6>
                                            <div className="d-flex">
                                                <input
                                                    readOnly={true}
                                                    type="text"
                                                    id="urlField"
                                                    value={shortLink}
                                                ></input>
                                                <CopyToClipboard
                                                    text={shortLink}
                                                    onCopy={() => {
                                                        toast.success(`${t("TopBar.LinkCopied")}`);
                                                    }}>
                                                    <button id="copyButton">
                                                        <FontAwesomeIcon icon={faCopy}/>
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </Dropdown.Menu>
                                    }
                                </Dropdown>
                            </li>
                            <li>
                                <a
                                    onClick={() =>
                                        handleFavoriteHotelItem(
                                            loginconfig,
                                            dispatch,
                                            searchfilters,
                                            searchfilters?.selectedHotel,
                                            appconfig,
                                            null,
                                            null,
                                            booking?.flight
                                        )
                                    }
                                >
                                    <img
                                        src={
                                            selectedHotel?.favoriteGuid ? HeartFilledIcon : HeartIcon
                                        }
                                        alt={"heart-icon"}
                                    />
                                    {selectedHotel?.favoriteGuid
                                        ? t("Buttons.Saved")
                                        : t("Buttons.Save")}
                                </a>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    );
};

TopBar.propTypes = {
    isMap: PropTypes.bool
}

export default TopBar;
