import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Col, Row } from "react-bootstrap";
import ListViewCard from "../../../HotelItem/ListViewCard";
import ListViewCardSmall from "../../../HotelItem/ListViewCardSmall";
import "./style.scss";
import { useTranslation } from "react-i18next";

const InnerTabs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="inner-tab-controls">
              <Nav.Item>
                <Nav.Link eventKey="first">{t("UserTab.Upcoming")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">{t("UserTab.Completed")}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="for-dekstop">
                  <ListViewCard />
                  <ListViewCard />
                </div>
                <div className="for-small">
                  <ListViewCardSmall />
                  <ListViewCardSmall />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="for-dekstop">
                  <ListViewCard />
                  <ListViewCard />
                </div>
                <div className="for-small">
                  <ListViewCardSmall />
                  <ListViewCardSmall />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default InnerTabs;
