import {faMapMarkerAlt, faStar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AeroplaneBed from "../../../assets/images/icons/aroplane-bed.svg";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import "./style.scss";
import "react-step-progress-bar/styles.css";

const DetailsContent = () => {
    const {t} = useTranslation();
    const {selectedHotel} = useSelector(getSearchFilters);
    return (
        <div className="content-holder">
            <h6 className="subtitle">
                <img src={AeroplaneBed} alt={"aeroplane"}/>
                {t("HotelInclFlight").toUpperCase()}
            </h6>
            <h2 className="heading heading-3 medium">{selectedHotel?.name}</h2>
            <div className="more-info">
                <div className="star">
                    {[1, 2, 3, 4, 5].map((item, index) => {
                        if (selectedHotel?.starRating && item <= Number(selectedHotel?.starRating))
                            return <FontAwesomeIcon key={index} icon={faStar} className="filled"/>
                        else return <FontAwesomeIcon key={index} icon={faStar} className="blank"/>
                    })}
                    <span>
                        {selectedHotel?.guestRating} {selectedHotel?.guestReviewCount && `(${selectedHotel?.guestReviewCount})`}
                    </span>
                </div>
                <p className="location">
                    <FontAwesomeIcon icon={faMapMarkerAlt}/> {selectedHotel?.address}
                </p>
            </div>

            {selectedHotel?.description &&
                Object.keys(selectedHotel?.description)?.map((type, index) => {
                    return <p key={index}>{selectedHotel?.description[type]}</p>;
                })}
        </div>
    );
};

export default DetailsContent;
