import AppConfig from "../helpers/config";
const BASE_URL = AppConfig.baseApiURL;

export const API_HOME_PACKAGE_TYPES = `${BASE_URL}/GeniusTravelSingular/GetGeniusTravelPackageTypes`;
export const API_SEARCH_ONLY_LISTING = `${BASE_URL}/GeniusTravelSingular/SearchOnlyListing`;
export const API_SEARCH_PACKAGE_DETAIL = `${BASE_URL}/GeniusTravelSingular/GetPackageDetails`;
export const API_SEARCH_MORE_FLIGHTS = `${BASE_URL}/GeniusTravelCommon/MoreFlightsSearch`;

// auth
export const API_LOGIN = `${BASE_URL}/GeniusTravelProfile/GetUserProfile`;
export const API_REGISTER = `${BASE_URL}/GeniusTravelProfile/InsertUserProfile`;
export const API_UPDATE_PROFILE = `${BASE_URL}/GeniusTravelProfile/UpdateUserProfile`;
export const API_GET_SEARCHED_AIRPORTS = `${BASE_URL}/GeniusTravelSingular/SearchAirports`;
export const API_RESET_PASSWORD = `${BASE_URL}/GeniusTravelProfile/ResetPassword`;
export const API_DELETE_ACCOUNT = `${BASE_URL}/GeniusTravelProfile/DeleteUserProfile`;
// export const API_VALIDATE_AUTH = `https://geniustravelsociallogin.cherimoya.be/api/ExternalAuth/Validate`;

// Favorites
export const GET_FAVORITES = `${BASE_URL}/GeniusTravelProfile/GetFavorites`;
export const DELETE_FAVORITES = `${BASE_URL}/GeniusTravelProfile/DeleteFavorite`;
export const INSERT_FAVORITES = `${BASE_URL}/GeniusTravelProfile/InsertFavorite`;

// communication
// export const INSERT_CUSTOMER_FEEDBACK = `${BASE_URL}/GeniusTravelCommunication/InsertCustomerFeedback`
export const API_GET_FAQ = `${BASE_URL}/GeniusTravelCommunication/GetFAQ`

// app apis
export const API_GET_BEST_DATES_CALENDER = `${BASE_URL}/GeniusTravelCommon/GetBestTravelDates`;
export const API_GET_COUNTRY_SEARCH_LISTING = `${BASE_URL}/GeniusTravelCommon/smartLookup`;
export const API_GET_TRANSACTION_ID = `${BASE_URL}/GeniusTravelCommunication/CreateTransactionId`;
