import React from "react";
import RoomImage from "../../../assets/images/hotal-image-1.png";
// import { useNavigate } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import { getCurrencyBadge, moveNumberToEnd } from "../../../helpers/common";
import { getBooking } from "../../../redux/slices/bookingSlice";
// import { getAppConfig } from "../../../redux/slices/appconfigSlice";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RoomCardModal from "../../Modals/RoomCardModal";

const RoomCard = ({
    room,
    tempPrice = null,
    currentSlideIndex = null,
    index = null,
    setShouldStop = null
}) => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const bookingState = useSelector(getBooking);
    // const appconfig = useSelector(getAppConfig);

    const [modalShow, setModalShow] = React.useState(false);

    const area = room?.amenities
        ?.filter((f) => f.id === "4092")[0]
        ?.name?.match(/\d/g)
        .join("");

    const getImageSource = () => {
        if (room?.media?.length) {
            return room?.media[0]?.url;
        }
        return RoomImage;
    };

    const getBedDescription = () =>
        room?.bedTypeOptions && room?.bedTypeOptions[0]?.description
            ? room?.bedTypeOptions[0]?.description
            : "-";

    const getSurchargesString = (surcharges) => {
        if (surcharges?.includes("+")) {
            return surcharges;
        }
        return surcharges?.includes("-") ? `${surcharges}` : `+${surcharges}`;
    };

    const tempPriceStyle = {
        color:
            tempPrice && Number(tempPrice) < 0 && currentSlideIndex === index
                ? "#05CE78"
                : "#1F6DE7",
    };

    return (
        <div className={"room-card"}>
            <div className="room-card-header">
                <img src={getImageSource()} alt={"icon"} />
            </div>
            <div className="room-card-body">
                <h6 className="ellipis">{room?.description}</h6>
                <ul className="tabs_tag">
                    <li>{getBedDescription()}</li>
                    {area && <li>{area + " sq m"}</li>}
                    <li>
                        {room?.ratePlans[0]?.freeBreakfast
                            ? t("InclBreakfast")
                            : t("NoBreakfast")}
                    </li>
                </ul>

                <ul className="list">
                    {room?.amenities?.map((amenity, index) => {
                        return <li key={index}>{moveNumberToEnd(amenity?.name)}</li>;
                    })}
                </ul>
            </div>
            <div className="room-card-footer">
                <p className="addon" style={tempPriceStyle}>
                    {`${getCurrencyBadge(
                        bookingState?.flight?.price?.averageCostPerPerson?.currency
                    )} ${getSurchargesString(
                        tempPrice &&
                            Number(tempPrice) !== 0 &&
                            currentSlideIndex === index
                            ? tempPrice
                            : Number(room?.rateDifference).toFixed(2)
                    ) || "0.00"
                        }`}
                    <span> p.p</span>
                </p>
                <a className="action-btn" onClick={() => {
                    setModalShow(true);
                    setShouldStop(true);
                }}>{t("Buttons.View")}</a>
            </div>
            <RoomCardModal room={room} show={modalShow} onHide={() => {
                setModalShow(false);
                setShouldStop(false);
            }} />
        </div>
    );
};

RoomCard.propTypes = {
    room: PropTypes.object,
    roomIndex: PropTypes.number,
    tempPrice: PropTypes.any,
    currentSlideIndex: PropTypes.any,
    index: PropTypes.any,
    setShouldStop: PropTypes.func
};

export default RoomCard;
