import React from "react";
import "./styles.scss";
import Loader from "../../components/Loader/Loader";

const BlankPage = () => {
    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <Loader/>
            </div>
        </div>
    );
};

export default BlankPage;
