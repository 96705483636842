import React from "react";
import { useTranslation } from "react-i18next";
import DealImage from "../../assets/images/deal.png";
import BadgeIcon from "../../assets/images/icons/aroplane-bed.svg";
import "./style.scss";

const DealCard = () => {
  const { t } = useTranslation();

  return (
    <div className="deal-card">
      <div className="deal-card-img">
        <img src={DealImage}  alt={'deal-icon'}/>
      </div>
      <div className="card-badge">
        <img src={BadgeIcon}  alt={'badge-icon'}/>
        <span>{t("HotelInclFlight")}</span>
      </div>
      <div className="text">
        <p className="fs-12 white-text m-0 font-noto">England</p>
        <h6 className="fs-20 white-text m-0 ellipsis ">Ynys Llanddwyn Hotel</h6>
        <p className="price">
          <sup className="symbol">€</sup>789{" "}
          <sup className="static">/total</sup>
        </p>
      </div>
    </div>
  );
};

export default DealCard;
