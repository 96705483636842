import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HotelImage1 from "../../../assets/images/hotal-image-1.png";
import { getSearchFilters } from "../../../redux/slices/searchFilterSlice";
import "./style.scss";
import { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import PropTypes from "prop-types";
import PhotoJson from '../../../assets/images/lottieViews/photos.json';
import Lottie from "lottie-react";
import {getBooking} from "../../../redux/slices/bookingSlice";

const DetailsImage = ({ images, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedHotel } = useSelector(getSearchFilters);
  const {room} = useSelector(getBooking);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const extractImage = (index) => {
    if (images && images?.length && images[index]) {
      return images[index]?.url;
    }
    return HotelImage1;
  };

  const getGetIdIndex = () => {
    if (type === "hotel") {
      return selectedHotel?.id;
    }

    return room.roomKey;
  };

  return (
    <div id="detailsImage" className="details-image-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="image-holder large">
              <img
                onClick={() => openImageViewer(0)}
                src={extractImage(0)}
                alt={"1-icon"}
              />
              <div className="view-gallery-action">
                <a
                  onClick={() =>
                    navigate("/gallery/" + getGetIdIndex() + "/" + type)
                  }
                >
                  <Lottie animationData={PhotoJson} loop={true} className='photo_animation' />
                  {t("ShowAllPhotos")}
                </a>
              </div>
            </div>

            
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="image-holder small">
              <img
                onClick={() => openImageViewer(1)}
                src={extractImage(1)}
                alt={"3-icon"}
              />
            </div>
            <div className="image-holder small">
              <img
                onClick={() => openImageViewer(2)}
                src={extractImage(2)}
                alt={"4-icon"}
              />
            </div>
          </div>
        </div>
      </div>
      {isViewerOpen && (
        <div className={"image-viewer-container"}>
          <ImageViewer
            src={images.map((i) => i?.url)}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </div>
      )}
    </div>
  );
};

DetailsImage.propTypes = {
  images: PropTypes.array,
  type: PropTypes.string,
}

export default DetailsImage;
