import React from "react";
import Slider from "react-slick";
import DealCard from "../../DealCard/DealCard";
import "./style.scss";
import PropTypes from "prop-types";

const Deal = () => {
  function SampleNextArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
            onClick={onClick}
        ></span>
    );
  }

  function SamplePrevArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
            onClick={onClick}
        ></span>
    );
  }

  SampleNextArrow.propTypes = {
    onClick: PropTypes.func,
  }

  SamplePrevArrow.propTypes = {
    onClick: PropTypes.func,
  }

  const settings = {
    className: "center",
    slidesToShow: 3,
    speed: 700,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "180px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
        },
      },
    ],
  };
  return (
    <section id="deal">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="deals-Slider">
              <Slider {...settings}>
                <DealCard />
                <DealCard />
                <DealCard />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Deal;
