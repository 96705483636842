import { get, post } from "./HttpClient";
import {
  API_GET_BEST_DATES_CALENDER,
  API_GET_COUNTRY_SEARCH_LISTING,
  API_GET_TRANSACTION_ID,
  API_SEARCH_ONLY_LISTING,
} from "./ApisRoutes";

export const GetBestTravelDates = ({ month, persona }) => {
  return new Promise((resolve, reject) => {
    get(
      `${API_GET_BEST_DATES_CALENDER}?searchMonth=${month}&persona=${persona}`
    )
      .then((response) => {
        const datesObj = response?.data;
        resolve(datesObj);
      })
      .catch((error) => {
        console.log(error, "dates response error");
        reject(error);
      });
  });
};

export const SearchCountries = (params) => {
  return new Promise((resolve, reject) => {
    post(API_GET_COUNTRY_SEARCH_LISTING, params)
      .then((response) => {
        const datesObj = response?.data;
        resolve(datesObj);
      })
      .catch((error) => {
        console.log(error, "dates response error");
        reject(error);
      });
  });
};

export const GetMetaDataOnlyForHotelService = (params) => {
  console.log(params, "search");

  return new Promise((resolve, reject) => {
    post(API_SEARCH_ONLY_LISTING, params)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        console.log(error, "status");
        reject(error);
      });
  });
};

export const GetSearchOnlyForHotel = (params) => {
  console.log({ params }, "search");

  return new Promise((resolve, reject) => {
    post(API_SEARCH_ONLY_LISTING, params)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        console.log(error, "status");
        reject(error);
      });
  });
};

export const ServiceGetTransactionId = (params) => {
  return new Promise((resolve, reject) => {
    post(API_GET_TRANSACTION_ID, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
