import React, {useEffect} from "react";
import "./style.scss";
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setBranchData} from "../../redux/slices/appconfigSlice";
import HotelItemBranch from "../HotelItem/ListViewCardBranch";
import HotelItemBranchSmall from "../HotelItem/ListViewCardBranchSmall";

const HotelItemCardModal = () => {
    const dispatch = useDispatch()
    const appconfig = useSelector((state) => state.appconfig);

    useEffect(() => {
        console.log(appconfig?.branchData, 'branch data')
    }, [appconfig?.branchData]);

    return (
        <Modal className="hotelcard-modal" centered show={appconfig?.branchData}
               onHide={() => dispatch(setBranchData(null))} backdrop="static">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="for-dekstop">
                    <HotelItemBranch offer={appconfig?.branchData}/>
                </div>
                <div className="for-small">
                    <HotelItemBranchSmall offer={appconfig?.branchData}/>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HotelItemCardModal;
