import { post } from "./HttpClient"
import {  API_UPDATE_PROFILE } from "./ApisRoutes"

export const UpdateProfileService = (params) => {
    return new Promise((resolve, reject) => {
        post(API_UPDATE_PROFILE, params).then(response => {
            const _response = response?.data;
            resolve(_response);
        }).catch(error => {
            console.log(error, "Register response error");
            reject(error);
        });
    });
}
