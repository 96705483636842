import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./style.scss";
import PropTypes from "prop-types";

const FaqAccordion = (props) => {
  return (
    <Accordion className="faq-accordion" defaultActiveKey="0">
      <Accordion.Item eventKey="1">
        <Accordion.Header>{props.question}</Accordion.Header>
        <Accordion.Body
          dangerouslySetInnerHTML={{ __html: props.answer }}
        ></Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

FaqAccordion.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
}

export default FaqAccordion;
