import { post } from "./HttpClient";
import {
  API_SEARCH_PACKAGE_DETAIL,
  API_SEARCH_MORE_FLIGHTS,
} from "./ApisRoutes";

export const GetHotelDetailsService = (params) => {
  return new Promise((resolve, reject) => {
    post(API_SEARCH_PACKAGE_DETAIL, params)
      .then((response) => {
        const _hotelDetails = response?.data;
        resolve(_hotelDetails);
      })
      .catch((error) => {
        console.log(error, "response detailsss");
        reject(error);
      });
  });
};

export const GetFlightDetailsService = (params) => {
  return new Promise((resolve, reject) => {
    post(API_SEARCH_MORE_FLIGHTS, params)
      .then((response) => {
        const _flightDetails = response?.data;
        resolve(_flightDetails);
      })
      .catch((error) => {
        console.log(error, "flight response detailsss");
        reject(error);
      });
  });
};
