import React, { useEffect } from "react";
import HotalItem from "../HotelItem/GridViewCArd";
import Slider from "react-slick";
import "./style.scss";
import { useSelector } from "react-redux";
import { getAppConfig } from "../../redux/slices/appconfigSlice";
import PropTypes from "prop-types";

const ListingSlider = () => {
  const appconfig = useSelector(getAppConfig);
  useEffect(() => {}, [appconfig.offers]);

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: appconfig?.offers?.length > 3,
    centerPadding: "150px",
    slidesToShow: 9,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 200,
    initialSlide: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 3400,
        settings: {
          slidesToShow: 8,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 2900,
        settings: {
          slidesToShow: 7,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 2550,
        settings: {
          slidesToShow: 6,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 2130,
        settings: {
          slidesToShow: 5,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1530,
        settings: {
          slidesToShow: 4,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
        },
      },
    ],
  };

  function SampleNextArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
            onClick={onClick}
        ></span>
    );
  }

  function SamplePrevArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
            onClick={onClick}
        ></span>
    );
  }

  SampleNextArrow.propTypes = {
    onClick: PropTypes.func,
  }

  SamplePrevArrow.propTypes = {
    onClick: PropTypes.func,
  }

  return (
    <Slider {...settings}>
      {appconfig?.offers?.map((offer, index) => {
        return <HotalItem key={index} offer={offer} index={index} />;
      })}
    </Slider>
  );
};

export default ListingSlider;
