import React from "react";
import AppHeader from "../../components/header";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import ImageGallery from "../../components/sections/ImageGallery/ImageGallery";
import Footer from "../../components/footer";
import "./style.scss";

const Gallery = () => {
  return (
    <div className="main-wrapper">
      <AppHeader />
      <main className="body-wrapper">
        <Topbar />
        <ImageGallery />
      </main>
      <Footer />
    </div>
  );
};

export default Gallery;
