import React from "react";
import { useTranslation } from "react-i18next";
import ImageWithText from "../../ImageWithText/ImageWithText";

import "./style.scss";

const Highlights = () => {
  const { t } = useTranslation();

  return (
    <section id="highlights">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title text-center">
              <h2 className="heading heading-1 font-poppings medium">
                {t("HighlightsIn")} Spain
              </h2>
            </div>
            <div className="col-12">
              <ImageWithText />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Highlights;
