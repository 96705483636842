import React from "react";
import Image from "../../assets/images/hightlight.png";
import "./style.scss";
const ImageWithText = () => {
  return (
    <div className="image-width-text-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="content-holder">
              <h6>1. Ontdek Sherry in Jerez de la Frontera</h6>
              <p>
                In Spanje vind je heerlijke wijnen. Maar in Jerez de la Frontera
                staat de Sherry centraal. Bezoek een van de bodegas en proef de
                verschillende soorten Sherry. Geniet van dit drankje met tapas
                voor een culinaire ervaring.
              </p>
              <h6>2. Bezoek Barcelona</h6>
              <p>
                Bezoek het Park Güell, de fontein van Montjuïc, Sagrada Família
                en slenter over de Rambla. Barcelona is een fantastische
                bestemming met veel te zien voor jong en oud!
              </p>
              <h6>3. Zon, zee en strand aan de costa&apos;s</h6>
              <p>
                Met een 5000 kilometer lange kust is het heerlijk genieten aan
                de Spaanse costa’s. Je kunt hier zonnebaden en zwemmen en er
                zijn vele watersportactiviteiten.
              </p>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="image-holder">
              <img src={Image} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithText;
