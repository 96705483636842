import React from "react";
import "./style.scss";
import FlipUnitContainer from "./FlipUnitContainer";
import PropTypes from "prop-types";

const FlipClock = ({price, oldPrice}) => {
    const getPriceArray = (price) => {
        let _price = [];
        if (price) {
            let value = price?.toString()?.split('');
            let valueIndex = 1;
            for (let index = 0; index <= value.length - 1; index++) {
                if (value?.length && value[value?.length - valueIndex]) {
                    _price.unshift(value[value.length - valueIndex]);
                } else {
                    _price.unshift('');
                }
                valueIndex++;
            }
        }
        return _price;
    }

    const _price = getPriceArray(price);
    const _oldPrice = getPriceArray(oldPrice);

    return (
        <div className={'flipClock'}>
            {_price?.length && _price.map((digit, index) => (
                <FlipUnitContainer
                    key={index}
                    previousNumber={_oldPrice[index] || 0}
                    digit={digit}
                    shuffle={digit !== _oldPrice[index] || 0}
                    index={index}
                />

            ))}
        </div>
    )
}

FlipClock.propTypes = {
    price: PropTypes.any,
    oldPrice: PropTypes.any
}

export default React.memo(FlipClock);
