import React from "react";
import AppHeader from "../../components/header";
import TopSection from "../../components/sections/TopSection/index";
import PackagesSection from "../../components/sections/HotelItemSection/index";
/* eslint-disable */
import ExploreGlobe from "../../components/sections/ExploreGlobal/ExploreGlobal"; // don't remove this import
/* eslint-enable */
import CheapestDestinations from "../../components/sections/CheapestDestinations/CheapestDestinations";
import BlogCardSection from "../../components/sections/HomepageBlogCard/HomepageBlogCard";
import MobileApps from "../../components/sections/MobileApps/MobileApps";
import TextSection from "../../components/sections/BottomTextSetion/BottomTextSetion";
import Footer from "../../components/footer";
import "./styles.scss";
import HotelItemCardModal from "../../components/Modals/HotelItemCardModal";
import PublicBeta from "../../assets/images/icons/Public-Beta.svg";

const Home = () => {

    return (
        <>
            <img className="public-beta" src={PublicBeta} alt=""/>
            <div className="main-wrapper">
                <AppHeader/>
                <main className="body-wrapper">
                    <TopSection/>
                    <PackagesSection/>
                    <CheapestDestinations/>
                    <BlogCardSection/>
                    <MobileApps/>
                    <TextSection/>
                    <HotelItemCardModal/>
                </main>
                <Footer/>
            </div>
        </>
    );
};

export default Home;
