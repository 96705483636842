import React, { useEffect, useRef, useState } from "react";
import AppHeader from "../../components/header";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import DetailsContent from "../../components/sections/DetailsContent/DetailsContent";
import DetailImages from "../../components/sections/DetailsImage/DetailsImage";
import DetailCard from "../../components/sections/DetailsCard/DetailsCard";
import RoomDetails from "../../components/sections/RoomCardSlider/RoomCardSlider";
import FlightDetails from "../../components/sections/FlightSlider/FlightSlider";
import TextSection from "../../components/sections/BottomTextSetion/BottomTextSetion";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import RoomIcon from "../../assets/images/bt-room.svg";
import FlightIcon from "../../assets/images/icons/flight.svg";
import ConfirmIcon from "../../assets/images/bt-confirm.svg";
import {
    getAppConfig,
    setActivityIndicator,
    setOffers,
} from "../../redux/slices/appconfigSlice";
import {
    GetFlightDetailsService,
    GetHotelDetailsService,
} from "../../services/HotelDetailServices";
import {
    getSearchFilters,
    setSelectedHotel,
    setSelectedHotelDetail,
    setRoomFlights,
    setFlightCabinClass,
    setPaginatedRoomTypes,
    setPaginatedRoomFlights,
} from "../../redux/slices/searchFilterSlice";
import {
    getBooking,
    setHotelBooking,
    setFlight,
    setHotelRoom,
    resetBookingState,
} from "../../redux/slices/bookingSlice";
import {
    calculateRoomsUpSell,
    getFlightOfferFromAppConfigOffers,
    getFlightsParams, getTravelerCountFromRoomUrl, replaceQueryParam,
} from "../../helpers/common";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import { useNavigate } from "react-router-dom";

const Deatils = () => {
    const url = window.location.href;
    const { t } = useTranslation();
    const objUrl = new URL(url);
    const searchParams = new URLSearchParams(objUrl.search);
    const hotelId = searchParams.get("hotelId");
    const isFav = searchParams.get("fav");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bookingCard = useRef(null);
    const searchfilters = useSelector(getSearchFilters);
    const loginConfig = useSelector(getLoginConfig);
    const bookingState = useSelector(getBooking);
    const appConfig = useSelector(getAppConfig);
    const { room, flight } = useSelector(getBooking);
    const [justArrived, setJustArrived] = useState(true);
    const [roomsFetched, setRoomsFetched] = useState(false);
    const [flightsFetched, setFlightsFetched] = useState(false);
    const amenityHeading = useRef(null);
    const { selectedHotel } = useSelector(getSearchFilters);
    const [amenities, setAmenities] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
        const params = getQueryParamsFromURL();
        if (!room && !flight) {
            localStorage.setItem('hotelDetailPageFocus', 'true');
            GetPackageDetailUpSellFalse(params);
        }

        if (hotelId !== searchfilters?.selectedHotel?.id) {
            dispatch(resetBookingState());
            dispatch(setSelectedHotel(null));
            dispatch(setSelectedHotelDetail([]));
            dispatch(setPaginatedRoomTypes([]));
            dispatch(setRoomFlights([]));
            dispatch(setPaginatedRoomFlights([]));
            // GetPackageDetailUpSellFalse(params);
        } else {
            const _roomFetched = localStorage.getItem('roomFetched');
            const _flightFetched = localStorage.getItem('flightFetched');

            setRoomsFetched(Boolean(_roomFetched));
            setFlightsFetched(Boolean(_flightFetched));
        }

        return () => {
            localStorage.setItem('hotelDetailPageFocus', 'false');
            localStorage.setItem('roomFetched', 'true');
            localStorage.setItem('flightFetched', 'true');
        }
    }, []);

    useEffect(() => {
        if (!justArrived) {
            if (getQueryParamsFromURL()?.fav === "false") {
                navigate("/");
            }
        } else {
            setJustArrived(false);
        }
    }, [loginConfig.locale, loginConfig.currency]);

    useEffect(() => {
        if (selectedHotel?.hotelAmenities?.length) {
            if (showMore) {
                setAmenities(selectedHotel?.hotelAmenities);
            } else {
                setAmenities(selectedHotel?.hotelAmenities?.slice(0, 9));
            }
        }
    }, [selectedHotel, showMore]);

    // Extracting params from url
    const getQueryParamsFromURL = () => {
        const params = {
            upSell: false,
            Adults: Number(searchParams.get("Adults")),
            Children: Number(searchParams.get("Children")),
            Infants: Number(searchParams.get("Infants")),
            Locale: searchParams.get("Locale"),
            fav: searchParams.get("fav"),
            // showFlights: searchParams.get("showFlights"),
            roomKey: searchParams.get("roomKey"),
            GUID: searchParams.get("GUID"),
            NumberOfStops: searchParams.get("NumberOfStops"),
            Departure: searchParams.get("Departure"),
            Destination: searchParams.get("Destination"),
            DepartureDate: searchParams.get("DepartureDate"),
            ReturnDate: searchParams.get("ReturnDate"),
            apiDetailsUri: url.split("apiDetailsUri=").pop(),
        };

        if (searchParams.get("flightCabinClass")) {
            params["flightCabinClass"] = searchfilters?.flightCabinClass;
        }

        return params;
    };

    const [steps, setSteps] = useState([
        { name: t("chooseRoom"), completed: true, icon: RoomIcon },
        { name: t("chooseFlight"), completed: false, icon: FlightIcon },
        { name: t("confirmBooking"), completed: false, icon: ConfirmIcon },
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSteps(prevSteps => prevSteps.map((step, index) => {
                if (index === prevSteps.length - 1) {
                    return { ...step, completed: true };
                } else {
                    return step;
                }
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, []); // Empty dependency array to run the effect only once on mount

    function calculatePercentage() {
        if (steps[2]?.completed) {
            return 100;
        } else if (steps[1]?.completed) {
            return 50;
        } else if (steps[0]?.completed) {
            return 0;
        } else return 0;
    }

    const GetPackageDetailUpSellFalse = (params) => {
        // console.log({params}, "getHotelDetails Params");
        delete params.fav;
        dispatch(setActivityIndicator(true));
        GetHotelDetailsService(params)
            .then((_hotelDetails) => {
                // console.log({_hotelDetails}, "Combined => false");

                dispatch(setActivityIndicator(false));

                const combinedAmenities = [
                    ..._hotelDetails.packageDetails.hotelOffer.hotels[0].hotelAmenities,
                    ..._hotelDetails.packageDetails.hotelOffer.hotels[0].roomAmenities,
                ];
                const roomsTypes =
                    _hotelDetails?.packageDetails?.hotelOffer?.hotels[0]?.roomTypes;
                const hotel = _hotelDetails?.packageDetails?.hotelOffer?.hotels[0];

                // getting Flight object from appConfig store
                const flightOffer = isFav === 'true' ?
                    {
                        price: _hotelDetails?.price,
                        links: hotel.roomTypes[0].links,
                        surcharges: 0,
                        ...searchfilters.selectedFavoriteHotel?.objHotel?.newPackagedOffer?.flightOffer
                    } :
                    getFlightOfferFromAppConfigOffers(
                        appConfig,
                        hotelId,
                    );

                // console.log({flightOffer});

                const totalTravelers = getTravelerCountFromRoomUrl(
                    roomsTypes[0]?.links?.webDetails?.href,
                );

                const dataToAdd = {
                    ...hotel,
                    price: {
                        totalPrice: _hotelDetails?.price?.packageTotalPrice?.value,
                        currency: _hotelDetails?.price?.packageTotalPrice?.currency,
                    },
                    perPersonPrice: {
                        totalPrice: _hotelDetails?.price?.averageCostPerPerson?.value,
                        currency: _hotelDetails?.price?.averageCostPerPerson?.currency,
                    },
                    address:
                        hotel?.location?.address?.city +
                        ", " +
                        hotel?.location?.address?.country,
                    objHotel: hotel,
                    hotelAmenities: combinedAmenities,
                    guidForBooking: params.GUID,
                    flightOffer,
                    totalTravelers
                };

                dispatch(setHotelBooking(dataToAdd));
                dispatch(setSelectedHotel(dataToAdd));
                dispatch(setSelectedHotelDetail(roomsTypes));
                dispatch(setHotelRoom(roomsTypes[0]));
                const _params = replaceQueryParam('roomKey', roomsTypes[0]?.roomKey);
                navigate(`?${_params}`, { replace: true });

                // Below code is used for adding the flight to the redux.
                if (
                    searchfilters.roomFlights?.length &&
                    getQueryParamsFromURL()?.fav === "true"
                ) {
                    const _flightOffer = { ...searchfilters.roomFlights[0] };
                    _flightOffer["price"] = _hotelDetails?.price;
                    dispatch(setFlight(_flightOffer));
                }

                if (flightOffer) {
                    // console.log({flightOffer});
                    dispatch(setRoomFlights([flightOffer]));
                    dispatch(setFlight(flightOffer));
                }

                setTimeout(() => {
                    getHotelDetailsCombinedTrue(); // Getting other rooms for this hotel/offer;
                }, 2000);
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setActivityIndicator(false));
                elimatatePackageFromOffers(searchfilters?.selectedHotel);
            });
    };

    const getHotelDetailsCombinedTrue = () => {
        const params = getQueryParamsFromURL();
        params["Combined"] = true;
        delete params.fav;

        GetHotelDetailsService(params)
            .then((_hotelDetails) => {
                // console.log({_hotelDetails}, "Combined => true");
                let total = 0;
                const objUrl = new URL(
                    _hotelDetails?.packageDetails?.hotelOffer?.hotels[0]?.roomTypes[0]?.links?.webDetails?.href
                );
                if (objUrl) {
                    const searchParams = new URLSearchParams(objUrl.search);
                    const adultsCount = searchParams
                        .get("NumAdult")
                        ?.split("|")
                        .reduce(
                            (previous, current) => previous + Number(current.split(":")[1]),
                            0
                        );
                    const childCount = searchParams
                        .get("NumChild")
                        ?.split("|")
                        .reduce(
                            (previous, current) => previous + Number(current.split(":")[1]),
                            0
                        );
                    if (adultsCount) {
                        total = Number(total) + Number(adultsCount);
                    }
                    if (childCount) {
                        total = Number(total) + Number(childCount);
                    }
                }

                const roomsTypes =
                    _hotelDetails?.packageDetails?.hotelOffer?.hotels[0]?.roomTypes?.map(
                        (room) => {
                            return {
                                ...room,
                                rateDifference: (Number(room?.rateDifference) / total).toFixed(
                                    2
                                ),
                            };
                        }
                    );

                const is_Focus = localStorage.getItem('hotelDetailPageFocus');
                const _selectedRoom = roomsTypes[0];
                if (is_Focus === 'true') {
                    dispatch(setSelectedHotelDetail(roomsTypes));
                    dispatch(setHotelRoom(_selectedRoom));
                    const _params = replaceQueryParam('roomKey', _selectedRoom?.roomKey);
                    navigate(`?${_params}`, { replace: true });

                    setRoomsFetched(true);
                }
                getMoreFlights(_selectedRoom, roomsTypes);
            })
            .catch((error) => {
                setRoomsFetched(true);
                dispatch(setActivityIndicator(false));
                console.log(error);
            });
    };

    const getMoreFlights = (room, roomsTypes) => {
        const params = getFlightsParams(
            room,
            searchfilters,
            loginConfig,
            bookingState,
            appConfig,
            hotelId,
            getQueryParamsFromURL().GUID,
            getQueryParamsFromURL()?.fav === "true"
        );

        // console.log({params}, "params for getting more flights");

        if (searchfilters.flightCabinClass) {
            params["flightCabinClass"] = searchfilters.flightCabinClass;
        }

        GetFlightDetailsService(params)
            .then((_flightDetails) => {
                dispatch(setActivityIndicator(false));
                const is_Focus = localStorage.getItem('hotelDetailPageFocus');
                if (is_Focus === 'true') {

                    const sortedFlightsDetails = _flightDetails?.offers?.sort(
                        (a, b) =>
                            Number(a.price.averageCostPerPerson?.value) -
                            Number(b.price.averageCostPerPerson?.value),
                    );

                    const firstFlightPrice = Number(
                        sortedFlightsDetails[0]?.price?.averageCostPerPerson?.value,
                    );
                    const flightsArray = sortedFlightsDetails?.map((offer, index) => {
                        const price = offer.price;
                        const links = offer.links;
                        const surcharges = (
                            Number(offer.price.averageCostPerPerson?.value) - firstFlightPrice
                        ).toFixed(0);
                        return {
                            price,
                            surcharges,
                            links,
                            isSelected: index === 0,
                            ...offer?.newPackagedFlightOffer?.flightOffer,
                        };
                    });

                    dispatch(setRoomFlights(flightsArray));

                    const departureSegmentId = flightsArray[0]?.segmentIds[0];
                    const departureFlightSegment = flightsArray[0]?.segments?.filter(
                        (f) => f.segmentId === departureSegmentId
                    )[0];
                    const flightLeg = departureFlightSegment?.legs[0];

                    dispatch(setFlightCabinClass(flightLeg?.cabinClass));

                    const previousSelectedFlight = flightsArray?.filter(
                        (f) => f.clientSelected && f.offerId === params.FlightOfferId
                    );
                    if (previousSelectedFlight?.length) {
                        dispatch(setFlight(previousSelectedFlight[0]));
                        const _params = replaceQueryParam('offerId', previousSelectedFlight[0]?.offerId);
                        navigate(`?${_params}`, { replace: true });
                    } else {
                        const _selectedFlight = flightsArray[0];
                        dispatch(setFlight(_selectedFlight));
                        const _params = replaceQueryParam('offerId', _selectedFlight?.offerId);
                        navigate(`?${_params}`, { replace: true });
                    }
                    setFlightsFetched(true);
                }
            })
            .catch((error) => {
                console.log({ error });
                const _params = getQueryParamsFromURL();
                if (_params["roomKey"] !== room?.roomKey) {
                    let _roomsTypes = roomsTypes?.length
                        ? roomsTypes
                        : searchfilters.selectedHotelDetail;
                    const _room = _roomsTypes?.find(
                        (f) => f?.roomKey === _params["roomKey"]
                    );
                    if (_room) {
                        calculateRoomsUpSell(_room, _roomsTypes, dispatch).then((res) => {
                            console.log({ res });
                        });
                    }
                }
                dispatch(setActivityIndicator(false));
                setFlightsFetched(true);
            });
    };

    const elimatatePackageFromOffers = (_hotel) => {
        const index = appConfig.offers.findIndex((f) => f?.id === _hotel?.id);
        if (index !== -1) {
            dispatch(setOffers(appConfig.offers?.filter((f, i) => i !== index)));
        }
        navigate(-1);
    };

    return (
        <div className="main-wrapper flight-page">
            <AppHeader />
            <main className="body-wrapper">
                <Topbar />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <DetailImages
                                images={searchfilters?.selectedHotel?.media?.map((obj) => ({
                                    url: obj?.url,
                                }))}
                                type="hotel"
                            />
                        </div>
                        <div className="col-lg-5">
                            <div
                                id="detailsContent"
                                className="details-content-wrapper sidecontent"
                            >
                                <DetailsContent />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="detailsContent" className="details-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="col-lg-12 col-md-12">
                                    <div className="content-holder">
                                        <ul className="features-deatils row">
                                            <h6 ref={amenityHeading}>{t("Amenities")}</h6>
                                            {amenities.map((amenity, index) => {
                                                return (
                                                    <li className="col-md-4" key={index}>
                                                        {amenity?.name}
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                        <button
                                            className="showmore-btn"
                                            onClick={() => setShowMore(!showMore)}
                                        >
                                            {showMore ? t("ShowLess") : t("ShowMore")}
                                        </button>

                                        <div className="steps-box mobile-steps">
                                            <ProgressBar
                                                percent={calculatePercentage()}
                                                height={3}
                                                filledBackground="linear-gradient(to right, #007bff, #007bff)"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                {steps?.map((step, index) => (
                                                    <Step key={index} index={index} transition="scale">
                                                        {({ accomplished }) => (
                                                            <div className={"step"}>
                                                                <div
                                                                    className={`circle ${accomplished ? "active" : ""
                                                                        }`}
                                                                >
                                                                    <img
                                                                        className={"step-icon"}
                                                                        src={step?.icon}
                                                                        alt={"step-icon"}
                                                                    />
                                                                </div>
                                                                <p className={"step-label"}>{step?.name}</p>
                                                            </div>
                                                        )}
                                                    </Step>
                                                ))}
                                            </ProgressBar>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 forSmaller" ref={bookingCard}>
                                    {room && flight ? <DetailCard /> : ""}
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 forDesktop" ref={bookingCard}>
                                {room && flight ? <DetailCard /> : ""}
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container double_section">
                    <div className="row">
                        <div id={"rooms-section"} className="col-lg-6">
                            <RoomDetails roomsFetched={roomsFetched} />
                        </div>
                        <div id={"flights-section"} className="col-lg-6">
                            <FlightDetails flightsFetched={flightsFetched} />
                        </div>
                    </div>
                </div>

                <TextSection />
            </main>
            <Footer />
        </div>
    );
};

export default Deatils;
