import axios from "axios";
import AppConfig from "../helpers/config";
import {startApiLoading, stopApiLoading} from "../redux/slices/requestSlice";

const axiosApiInstance = axios.create();

export const post = (api, data, source) => {
    if (source) {
        const config = {cancelToken: source.token};
        return axiosApiInstance.post(api, data, config);
    }
    return axiosApiInstance.post(api, data);
};

export const postWithoutBody = (api) => {
    return axiosApiInstance.post(api);
};

export const get = (api) => {
    return axiosApiInstance.get(api);
};

axiosApiInstance.interceptors.request.use(
    async (config) => {
        const value = localStorage.getItem("auth_token");
        const store = (await import("../redux")).default; // Dynamically import store
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${value}`,
            'Content-Type': 'application/json',
        };

        if (config.method === "post") {
            const local = JSON.parse(
                localStorage.getItem("persist:gt-web:rootApp")
            );
            const currency = JSON.parse(local.loginconfig)?.currency?.code;

            if (
                config?.url?.includes("/SearchOnlyListing") ||
                config?.url?.includes("/GetPackageDetails") ||
                config?.url?.includes("/MoreFlightsSearch") ||
                config?.url?.includes("/InsertFavorite") ||
                config?.url?.includes("/DeleteFavorite")
            ) {
                const transaction_id = JSON.parse(local.appconfig)?.transaction_id;
                config.data = {
                    ...config.data,
                    transaction_id,
                    currency,
                };
            }
        }

        const source = axios.CancelToken.source();
        config.cancelToken = source.token;

        // Dispatch startApiLoading with cancelSource
        store.dispatch(startApiLoading({cancelSource: source}));

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    async (response) => {
        const store = (await import("../redux")).default; // Dynamically import store
        store.dispatch(stopApiLoading());
        return response;
    },
    async function (error) {
        console.log({error});
        const store = (await import("../redux")).default; // Dynamically import store
        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const res = await postWithoutBody(
                `${AppConfig.baseApiURL}/Authenticate/GetAccessToken`
            );
            await localStorage.setItem("auth_token", res.data.access_token);
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + res.data.access_token;
            return axiosApiInstance(originalRequest);
        }
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
        }
        store.dispatch(stopApiLoading());
        return Promise.reject(error);
    }
);
