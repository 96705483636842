import "./style.scss";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {SearchCountries} from "../../services/AppService";
import {useDispatch, useSelector} from "react-redux";
import {setDesireCountry} from "../../redux/slices/searchFilterSlice";
import {useTranslation} from "react-i18next";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import PropTypes from "prop-types";

const DesireCountryModal = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const loginConfig = useSelector(getLoginConfig);
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);

    const handleSearchingAPI = (e) => {
        const query = e.target.value;
        setCountry(query);
        const params = {searchTerm: query, Locale: loginConfig?.locale?.code};
        SearchCountries(params).then((response) => {
            setCountries(response?.locations);
        });
    };

    const selectionHandler = (country) => {
        dispatch(setDesireCountry(country));
        setCountry("")
        setCountries([]);
        props.onHide();
    };

    return (
        <Modal {...props} size="sm" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="fs-22 medium">{t("Modals.SearchDesireCountry")}</h4>
                <div className="search-form">
                    <div className="form-group">
                        <input
                            id="destination"
                            name="destination"
                            placeholder={t("Placeholders.TypeCountryName")}
                            value={country}
                            onChange={handleSearchingAPI}
                        />
                    </div>
                    <button className="btn-surprise" onClick={() => {
                        selectionHandler({
                            airportCode: "001",
                            displayName: loginConfig?.locale?.code === 'en_US' ?
                                "Surprise me - let Genius choose for you" :
                                "Verras me - laat Genius jouw bestemming kiezen"
                            ,
                            geoLocation: null,
                            id: "00001",
                            keyword: "Anywhere",
                            type: "MULTICITY"
                        })
                    }}>{t("SurpriseMe")}</button>
                </div>
                <ul className="suggestion-list">
                    {countries &&
                        countries?.map((country, index) => {
                            return (
                                <li
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: country.displayName}}
                                    onClick={() => selectionHandler(country)}
                                />
                            );
                        })}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

DesireCountryModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default DesireCountryModal;
