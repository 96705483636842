import { post } from "./HttpClient";
import { API_DELETE_ACCOUNT } from "./ApisRoutes";

export const DeleteAccountService = (params) => {
  return new Promise((resolve, reject) => {
    post(API_DELETE_ACCOUNT, params)
      .then((response) => {
        const _response = response?.data;
        resolve(_response);
      })
      .catch((error) => {
        console.log(error, "login response error");
        reject(error);
      });
  });
};
