import React from "react";
import Filter from "../Filter/index";
import Slider from "../../HotalListingSlider/HotalListingSlider";
import PkgSlider from "../../PackageSllider/index";
import "./style.scss";
import { getAppConfig } from "../../../redux/slices/appconfigSlice";
import ListView from "../../ItemsCardView/ItemsCardListView";
import { useSelector } from "react-redux";

const PackagesSection = () => {

  const appconfig = useSelector(getAppConfig);

  return (
    <section id="packages" className="packages-wrapper">
      <div className="packages-card-wrapper package-country">
        <div className="container-fluid p-0">
          <PkgSlider />
        </div>
      </div>
      <div className="container">
        <Filter />
      </div>
      <div className="packages-card-wrapper">
        <div className="container-fluid p-0">
          {appconfig.showRectangular ? <ListView /> : <Slider />}
        </div>
      </div>
    </section>
  );
};

export default PackagesSection;
