import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const TextSection = () => {
  const { t } = useTranslation();

  return (
    <section id="textSection" className="text-section-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Content-holder">
              <div className="title">
                <h2 className="heading heading-1 font-poppings medium">
                  {t("BlogCardList.heading")}
                </h2>
              </div>
              <div className="description">
                <p>{t("BlogCardList.description").split("|")[0]}</p>
                <br />
                <p>{t("BlogCardList.description").split("|")[1]}</p> <br />
                <p>{t("BlogCardList.description").split("|")[2]}</p> <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextSection;
