import React from "react";
import {useTranslation} from "react-i18next";
import LoaderImg from "../../assets/images/Genius-sun-loader.gif";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {getRequestAPIState, terminateRequest} from "../../redux/slices/requestSlice";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";

const Loader = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {apiLoading} = useSelector(getRequestAPIState);

    const handleCancelRequest = () => {
        console.log('terminate it');
        dispatch(setActivityIndicator(false));
        dispatch(terminateRequest()); // Dispatch terminateRequest to cancel API call
    };

    return (
        <div className="loader show">
            {apiLoading && (
                <div className='reset-btn' onClick={handleCancelRequest}>{t('Buttons.Reset')}</div>
            )}
            <div className="loader-content">
                <img src={LoaderImg} className={'loader_spinner'} alt={'loading-image'}/>
                <p>{t("Loading")} ...</p>
            </div>
        </div>
    );
};

export default Loader;
