const _colorSet = {
  white: "#fff",
  whiteFamilyOne: "#FEFEFE",
  whiteFamilyTwo: "#FFFFF3",
  whiteFamilyThree: "#E0E3EE33",
  offWhite: "#00000D",
  primaryColor: "#091E30",
  secondaryColor: "#05CE78",
  grayColor: "#596C7F",
  blackColor: "#000000",
  blackFamilyOne: "#212B36",
  chipesBorder: "#BDC6D7",
  blueColorOne: "#1F6DE7",
  greenColorOne: "#1FE79656",
  whiteColor: "#FFFFFF",
  orangeOne: "#FF8700",
  lightGray: "#777E90",
};
const _fontFamily = {
  popins: {
    regular: {
      family: "Poppins-Regular",
    },
    semiBold: {
      family: "Poppins-SemiBold",
    },
    medium: {
      family: "Poppins-Medium",
    },
  },
  noto: {
    regular: {
      family: "NotoSans",
    },
    bold: {
      family: "NotoSans-Bold",
    },
  },
};

const _fontSet = {
  xxxlarge: 96,
  xxlarge: 28,
  xlarge: 24,
  large: 21,
  middle: 18,
  xnormal: 16,
  normal: 14,
  small: 12,
  msmall: 11,
  xsmall: 10,
};

const _fontLineHeight = {
  ten: 10,
  tweleve: 12,
  twenty: 20,
  twentyOne: 21,
  twentyFive: 25,
  seventeen: 17,
  eighteen: 18,
  sixteen: 16,
  twentyseven: 27,
  fourteen: 14,
};

const _sizeSet = {
  buttonWidth: "70%",
  inputWidth: "80%",
  radius: 50,
};

const _styleSet = {
  menuBtn: {
    container: {
      backgroundColor: _colorSet.grayColor,
      borderRadius: 22.5,
      padding: 10,
      marginLeft: 10,
      marginRight: 10,
    },
    icon: {
      tintColor: "black",
      width: 15,
      height: 15,
    },
  },
  searchBar: {
    container: {
      marginLeft: 0,
      backgroundColor: 'transparent',
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      flex: 1,
    },
    input: {
      borderRadius: 10,
      color: "black",
    },
  },
  rightNavButton: {
    marginRight: 10,
  },
  backArrowStyle: {
    resizeMode: "contain",
    tintColor: "#eb5a6d",
    width: 25,
    height: 25,
    marginTop: 20,
    marginLeft: 10,
    transform: [{ scaleX: 1 }],
  },
};

const _popularDestinationStyle = {
  item: {
    width: 103,
    height: 70,
    borderRadius: 5,
    overflow: "hidden",
  },
  backgroundImage: {
    flex: 1,
    justifyContent: "center",
  },
  titleContainer: {
    position: "absolute",
    left: 6,
    bottom: 4,
  },
  title: {
    fontFamily: _fontFamily.popins.semiBold.family,
    fontSize: _fontSet.small,
    color: _colorSet.white,
  },
};

const _appChips = {
  item: {
    height: 35,
    minWidth: 115,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 17.5,
    marginLeft: 5,
    marginRight: 5,
    borderColor: _colorSet.chipesBorder,
  },

  title: {
    fontFamily: _fontFamily.popins.medium.family,
    fontSize: _fontSet.msmall,
    color: _colorSet.white,
    lineHeight: 17,
  },
};

const _rangeSelector = {
  slider: {},
  ContainerLeft: {
    flexDirection: "column",
    justifyContent: "space-between",
    // paddingTop: 8,
    // marginBottom: 0,
  },
  ContainerRight: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  ContainerText: {
    fontFamily: _fontFamily.noto.regular.family,
    fontSize: _fontSet.small,
    lineHeight: 12,
    color: _colorSet.white,

    // flex:1,
  },
};

const _FLEXROW = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignContent: "center",
};

// const SCREEN_WIDTH = Dimensions.get('screen').width;
// const SCREEN_HEIGHT = Dimensions.get('screen').height;

// const WINDOW_WIDTH = Dimensions.get('window').width;
// const WINDOW_HEIGHT = Dimensions.get('window').height;

const _EMAIL_REG_EXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;

const AppStyle = {
  colorSet: _colorSet,
  // iconSet: _iconSet,
  sizeSet: _sizeSet,
  fontSet: _fontSet,
  fontFamily: _fontFamily,
  styleSet: _styleSet,
  lineHeight: _fontLineHeight,
  // windowW: WINDOW_WIDTH,
  // windowH: WINDOW_HEIGHT,
  // screenW: SCREEN_WIDTH,
  // screenH: SCREEN_HEIGHT,
  popularDestinations: _popularDestinationStyle,
  appChips: _appChips,
  rangeSelector: _rangeSelector,
  viewFlexRowStartAlign: _FLEXROW,
  emailRegExp: _EMAIL_REG_EXP,
};


export default AppStyle;
