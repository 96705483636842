import React from "react";
import AreaImage from "../../../assets/images/papular-areas.png";
import "./style.scss";

const Areas = () => {
  return (
    <li>
      <a>
        <img src={AreaImage} />
        <div className="text">
          <span>Holiday in spain</span>
          <p>Ibiza</p>
        </div>
      </a>
    </li>
  );
};

export default Areas;
