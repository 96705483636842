import "./style.scss";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {
    setActivityIndicator,
    setModals,
} from "../../redux/slices/appconfigSlice";
import {useDispatch} from "react-redux";
import {RegisterService} from "../../services/RegisterService";
import {setIsLogin, setLoginUser} from "../../redux/slices/loginconfigSlice";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import SocialLogins from "../SocialLogins";

const SignUpModal = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [form, setForm] = useState({email: "", password: ""});
    const [inputType, setInputType] = useState(false);

    useEffect(() => {
        if (!props.show) {
            setForm({email: "", password: ""});
        }
    }, [props.show]);

    const onRegisterHandler = (e) => {
        e.preventDefault();
        dispatch(setModals({login: false, register: false, confirmation: false}));
        const params = {
            username: form.email,
            password: form.password,
            firstname: "",
            lastname: "",
        };
        dispatch(setActivityIndicator(true));
        RegisterService(params)
            .then((response) => {
                params.email = form.email;
                params.guid = response.guid;
                delete params["password"];
                dispatch(setActivityIndicator(false));
                dispatch(setLoginUser(params));
                dispatch(setIsLogin(true));
            })
            .catch((error) => {
                dispatch(setActivityIndicator(false));
                console.log({error});
                if (error?.response?.status === 400) {
                    toast.error(
                        error?.response?.data?.errors[0]?.description?.toString()
                    );
                }
                if (error?.response?.status === 401) {
                    toast.error(error?.response?.data?.message?.toString());
                }
            });
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="fs-38 medium">
                    {t("Modals.SignUpOn")} <span className="bold green-text">Genius</span>
                </h4>
                <form className="auth-form" onSubmit={onRegisterHandler}>
                    <div className="signup-form">
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder={t("Placeholders.EnterYourEmail")}
                                name="email"
                                id="email"
                                value={form.email}
                                required={true}
                                onChange={(e) => setForm({...form, email: e.target.value})}
                            />
                        </div>
                        <div className="form-group password-field">
                            <input
                                type={inputType ? "text" : "password"}
                                placeholder={t("Placeholders.SetYourPassword")}
                                name="password"
                                id="password"
                                value={form.password}
                                required={true}
                                onChange={(e) => setForm({...form, password: e.target.value})}
                            />
                            <FontAwesomeIcon onClick={() => setInputType(!inputType)} className="eyeicon"
                                             icon={!inputType ? faEye : faEyeSlash}/>
                        </div>
                        <div className="form-group login-btn">
                            <button className="submit-btn btn-primary" type="submit">
                                Sign up
                            </button>
                        </div>
                    </div>
                    <div className="action link">
                        <p className="m-0 fs-12">
                            {t("Modals.AlreadyHaveAnAccount")}?{" "}
                            <a
                                className="green-text static-link"
                                onClick={() =>
                                    dispatch(
                                        setModals({
                                            login: true,
                                            register: false,
                                            confirmation: false,
                                        })
                                    )
                                }
                            >
                                {t("Login")}
                            </a>
                        </p>
                    </div>

                    <SocialLogins/>

                </form>
            </Modal.Body>
        </Modal>
    )
        ;
};

SignUpModal.propTypes = {
    show: PropTypes.bool,
};

export default SignUpModal;
