import React from "react";
import BlogCard from "../../BlogCards/BlogCardList";
import "./style.scss";

const BlogCardSection = () => {
  return (
    <div id="blogCard" className="blog-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BlogCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCardSection;
