import React, {useEffect, useState} from "react";
import {ServiceGetTransactionId} from "../services/AppService";
import {useDispatch} from "react-redux";
import {setBranchData, setTransactionId} from "../redux/slices/appconfigSlice";
import Loader from "../components/Loader/Loader";
import PropTypes from "prop-types";
import branch from 'branch-sdk';
import AppConfig from "../helpers/config";
import {isMobile, isTablet} from "react-device-detect";

const TransactionWrapper = ({children}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const fullUrl = window.location.href;
    const isFetchingPage = fullUrl.includes('/fetching');

    useEffect(() => {
        if(!isFetchingPage){
            setTimeout(() => {
                getTransactionIdService();
            }, 1000);

            branch.init(AppConfig.branchKey);

            branch.data(function (err, data) {
                if (data?.data_parsed?.payload) {
                    const parsed = JSON.parse(data?.data_parsed?.payload);
                    if (parsed) {
                        console.log({parsed});
                        dispatch(setBranchData(parsed));
                    }
                }
            });

            return () => {
                dispatch(setTransactionId(null));
            };
        }else {
            setLoading(false);
        }
    }, []);

    const getTransactionIdService = () => {
        return new Promise((resolve, reject) => {
            ServiceGetTransactionId({
                transaction_channel: "site",
                transaction_campaign: (isMobile || isTablet) ? "GeniusMobile" : "GeniusWebsite",
            })
                .then((response) => {
                    dispatch(setTransactionId(response?.data?.transaction_id));
                    setTimeout(() => setLoading(false), 1000);
                    resolve(response);
                })
                .catch((error) => {
                    console.log({error});
                    reject(error);
                    setLoading(false);
                });
        }).catch((error) => {
            console.log({error});
        });
    };

    return <div>{loading ? <Loader/> : children}</div>;
};

TransactionWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TransactionWrapper;
