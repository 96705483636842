import React, {useEffect} from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import {useNavigate} from "react-router-dom";
import "./styles.scss";
import {useSelector} from "react-redux";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {useTranslation} from "react-i18next";

const Press = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const loginconfig = useSelector(getLoginConfig);

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    useEffect(() => {
        scrollToTop();
    }, [loginconfig?.locale?.code, loginconfig.currency]);

    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <div className="container">
                    <AppHeader/>
                    <main className="body-wrapper">
                        <div className="left-action-back-btn">
                            <a onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                        </div>
                        <h2 className="text-center">{t('AppFooter.Press')}</h2>
                        <p>{t("PressInfo")}</p>
                    </main>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Press;
