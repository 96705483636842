import React from "react";
// import Dropdown from "react-bootstrap/Dropdown";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../../assets/images/icons/icon-list.svg";
import GridIcon from "../../../assets/images/icons/grid-icon.svg";
import {
  getAppConfig,
  setShowRectangular,
} from "../../../redux/slices/appconfigSlice";
// import {
//   getSearchFilters,
//   setSortingOrder,
// } from "../../../redux/slices/searchFilterSlice";
import "./style.scss";

const Filter = () => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const appconfig = useSelector(getAppConfig);
  // const searchfilters = useSelector(getSearchFilters);

  return (
    <div id="filter">
      <div className="filter-wrapper desktop-only">
        <div className="right-sider-filter">
          {/*<div className="sort-by">*/}
          {/*  <span>{t("SortBy")}:</span>*/}
          {/*  <Dropdown onSelect={(value) => dispatch(setSortingOrder(value))}>*/}
          {/*    <Dropdown.Toggle>{t(searchfilters.sortingOrder)}</Dropdown.Toggle>*/}
          {/*    <Dropdown.Menu>*/}
          {/*      <Dropdown.Item*/}
          {/*        eventKey={"Price"}*/}
          {/*        active={searchfilters.sortingOrder === "Price"}*/}
          {/*      >*/}
          {/*        {t("Price")}*/}
          {/*      </Dropdown.Item>*/}
          {/*      <Dropdown.Item*/}
          {/*        eventKey={"Random"}*/}
          {/*        active={searchfilters.sortingOrder === "Random"}*/}
          {/*      >*/}
          {/*        {t("Random")}*/}
          {/*      </Dropdown.Item>*/}
          {/*      <Dropdown.Item*/}
          {/*        eventKey={"Rating"}*/}
          {/*        active={searchfilters.sortingOrder === "Rating"}*/}
          {/*      >*/}
          {/*        {t("Rating")}*/}
          {/*      </Dropdown.Item>*/}
          {/*    </Dropdown.Menu>*/}
          {/*  </Dropdown>*/}
          {/*</div>*/}
          <div className="list-grid-toggler">
            <a
              onClick={() =>
                dispatch(setShowRectangular(!appconfig.showRectangular))
              }
            >
              <img
                src={appconfig.showRectangular ? GridIcon : ListIcon}
                alt={"list-icon"}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="filter-mob-wrapper mobile-only">
        {/*<div className="sort-by">*/}
        {/*  <span>{t("SortBy")}:</span>*/}
        {/*  <Dropdown onSelect={(value) => dispatch(setSortingOrder(value))}>*/}
        {/*    <Dropdown.Toggle>{t(searchfilters.sortingOrder)}</Dropdown.Toggle>*/}
        {/*    <Dropdown.Menu>*/}
        {/*      <Dropdown.Item*/}
        {/*        eventKey={"Price"}*/}
        {/*        active={searchfilters.sortingOrder === "Price"}*/}
        {/*      >*/}
        {/*        {t("Price")}*/}
        {/*      </Dropdown.Item>*/}
        {/*      <Dropdown.Item*/}
        {/*        eventKey={"Random"}*/}
        {/*        active={searchfilters.sortingOrder === "Random"}*/}
        {/*      >*/}
        {/*        {t("Random")}*/}
        {/*      </Dropdown.Item>*/}
        {/*      <Dropdown.Item*/}
        {/*        eventKey={"Rating"}*/}
        {/*        active={searchfilters.sortingOrder === "Rating"}*/}
        {/*      >*/}
        {/*        {t("Rating")}*/}
        {/*      </Dropdown.Item>*/}
        {/*    </Dropdown.Menu>*/}
        {/*  </Dropdown>*/}
        {/*</div>*/}
        <div className="list-grid-toggler">
          <a
            onClick={() =>
              dispatch(setShowRectangular(!appconfig.showRectangular))
            }
          >
            <img
              src={appconfig.showRectangular ? GridIcon : ListIcon}
              alt={"list-icon"}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Filter;
