import { faShareSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import PropTypes from "prop-types";

const TabTitle = ({title}) => {
  const { t } = useTranslation();

  return (
    <div className="tab-title">
      <h2 className="white-text fs-38 medium">{title}</h2>
      {title === "My Favriotes" && (
        <a className="action">
          <FontAwesomeIcon icon={faShareSquare} /> {t("Share")}
        </a>
      )}
    </div>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default TabTitle;
