import React from "react";
import TabTitle from "./TabsTitle";
import InnerTabs from "./InnerTabs/InnerTabs";
import "./style.scss";
import { useTranslation } from "react-i18next";

const BookingTabContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <TabTitle title={t("AppHeader.Bookings")}></TabTitle>
      <div className="tabs-inner-content bookings-tab">
        <InnerTabs />
      </div>
    </>
  );
};

export default BookingTabContent;
