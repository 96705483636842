import {get} from "./HttpClient"
import {API_GET_SEARCHED_AIRPORTS} from "./ApisRoutes";

export const GetAirportsService = (search, locale) => {
    const airportsApi = API_GET_SEARCHED_AIRPORTS + "?Search=" + search + "&Locale=" + locale;
    return new Promise((resolve, reject) => {
        get(airportsApi).then(response => {
            resolve(response?.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}
