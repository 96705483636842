import { post, get } from "./HttpClient"
import {API_LOGIN} from "./ApisRoutes"

export const LoginService = (params) => {
    // console.log(params);
    return new Promise((resolve, reject) => {
        post(API_LOGIN, params).then(response => {
            const _loginResponse = response?.data;
            // console.log(_loginResponse);
            resolve(_loginResponse);
        }).catch(error => {
            console.log(error, "login response error");
            reject(error);
        });
    });
}

export const ValidateAuth = ({Provider, UserId}) => {
    return new Promise((resolve, reject) => {
        get(`https://geniustravelsociallogin.cherimoya.be/api/ExternalAuth/Validate?provider=${Provider}&userId=${UserId}`)
            .then(response => {
                const _loginResponse = response?.data;
                resolve(_loginResponse);
            })
            .catch(error => {
                console.log(error, 'login response error');
                reject(error);
            });
    });
};

export const PostValidateAuth = payload => {
    return new Promise((resolve, reject) => {
        post(
            'https://geniustravelsociallogin.cherimoya.be/api/ExternalAuth/SdkValidate',
            payload,
        )
            .then(response => {
                const _loginResponse = response?.data;
                resolve(_loginResponse);
            })
            .catch(error => {
                console.log(error, 'login response error');
                reject(error);
            });
    });
};
