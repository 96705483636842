import React from "react";
import AppHeader from "../../components/header";
import QuickFilter from "../../components/sections/QuickFilters";
import PackagesSection from "../../components/sections/HotelItemSection/index";
import PapulerAreas from "../../components/sections/PapularAreas/PapularAreas";
import Highlights from "../../components/sections/Highlights/Highlights";
import CheapestDestinations from "../../components/sections/CheapestDestinations/CheapestDestinations";
import BlogCardSection from "../../components/sections/HomepageBlogCard/HomepageBlogCard";
import Deal from "../../components/sections/Deal/Deal";
import TextSection from "../../components/sections/BottomTextSetion/BottomTextSetion";
import FAQs from "../../components/sections/FAQs/FAQs";
import Footer from "../../components/footer";
import "./style.scss";
import { useTranslation } from "react-i18next";

const Destinations = () => {
  const { t } = useTranslation();

  return (
    <div className="main-wrapper">
      <AppHeader />
      <main className="body-wrapper">
        <section id="top-section" className="top-section-wrapper">
          <div className="container">
            <div className="content-wrapper d-flex">
              <div className="content-hodler">
                <h1 className="heaidng fs-60 white-text medium font-poppings mb-3">
                  {t("OurDestinations")} <span className="green-text">Spain</span>
                </h1>
              </div>
            </div>
            <QuickFilter />
          </div>
        </section>
        <PackagesSection />
        <PapulerAreas />
        <Highlights />
        <CheapestDestinations />
        <BlogCardSection />
        <Deal />
        <TextSection />
        <FAQs />
      </main>
      <Footer />
    </div>
  );
};

export default Destinations;
