import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { setActivityIndicator } from "../../redux/slices/appconfigSlice";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { ResetPasswordService } from "../../services/ResetPasswordService";
import { toast } from "react-toastify";
import { _EMAIL_REG_EXP } from "../../helpers/common";
import PropTypes from "prop-types";

const ForgotPasswordModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!props.show) {
      setEmail("");
    }
  }, [props.show]);

  const validateForm = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      toast(t("ForgotPassword.EmptyFieldsMessage"));
    } else if (_EMAIL_REG_EXP.test(email) === false) {
      toast(t("ForgotPassword.InvalidEmailMessage"));
    } else {
      resetPassword();
    }
  };

  const resetPassword = () => {
    dispatch(setActivityIndicator(true));
    ResetPasswordService(email)
      .then((_response) => {
        dispatch(setActivityIndicator(false));
        toast(_response?.message);
        props.onHide();
      })
      .catch(() => {
        dispatch(setActivityIndicator(false));
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4 className="fs-38 medium">{t("ForgotPassword.Header")}</h4>
        <form className="auth-form login">
          <div className="form-group">
            <input
              value={email}
              type="email"
              placeholder={t("Email")}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="login-btn">
            <button onClick={validateForm}>
              {t("ForgotPassword.SendEmail")}
            </button>
          </div>
          <p className="fs-12 font-noto" style={{ marginTop: "30px" }}>
            {t("ForgotPassword.Description")}
          </p>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ForgotPasswordModal;
