// requestSlice.js
import {createSlice} from '@reduxjs/toolkit';

export const requestSlice = createSlice({
    name: 'requestAPI',
    initialState: {
        apiLoading: false,
        cancelSource: null,
    },
    reducers: {
        startApiLoading: (state, action) => {
            state.apiLoading = true;
            state.cancelSource = action.payload.cancelSource; // Save cancel function
        },
        stopApiLoading: state => {
            state.apiLoading = false;
            state.cancelSource = null;
        },
        terminateRequest: state => {
            if (state.cancelSource) {
                state.cancelSource.cancel('Request terminated by user');
            }
            state.apiLoading = false;
            state.cancelSource = null;
        },
    },
});

export const getRequestAPIState = (state) => state.requestAPI;

export const {
    startApiLoading,
    stopApiLoading,
    terminateRequest
} =
    requestSlice.actions;

export default requestSlice.reducer;
