import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "../pages/not-found";
import Home from "../pages/home";
import SearchPage from "../pages/search";
import DetailsPage from "../pages/hotelDetails/hotelDetails";
import GalleryPage from "../pages/Gallery/Gallery";
import RoomDetailPage from "../pages/RoomDetails/RoomDetails";
import Destinations from "../pages/Destination/Destination";
import UserDashborad from "../pages/UserDashboard/UserDashboard";
import Blog from "../pages/Blog/Blog";
import FAQScreen from "../pages/FAQs/FAQScreen";
import BookingTabContent from "../components/sections/UserTabContent/BookingTabContent";
import FavoriteTabContent from "../components/sections/UserTabContent/FavoriteTabsContent";
import SettingsTabContent from "../components/sections/UserTabContent/SettingsTabContent";
import AccountTabContent from "../components/sections/UserTabContent/AccountTabContent";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import MapPage from "../pages/Map";
import AboutUs from "../pages/AboutUs";
import Press from "../pages/Press";
import Vacancies from "../pages/Vacancies";
import AuthComponent from "../pages/Auth";
import TermsConditions from "../pages/TermsConditions/TermsCondition";
import BlankPage from "../pages/Blank/BlankPage";

function AppRoutes() {
  const appRoutes = [
    { path: "/", element: <Home /> },
    { path: "/auth", element: <AuthComponent /> },
    { path: "/search/:guid/:query", element: <SearchPage /> },
    { path: "/hotel/:query", element: <DetailsPage /> },
    { path: "/hotel/:hotelId/map", element: <MapPage /> },
    { path: "/gallery/:hotelId/:type", element: <GalleryPage /> },
    { path: "/room-detail/:query", element: <RoomDetailPage /> },
    { path: "/destinations", element: <Destinations /> },
    { path: "/me", element: <UserDashborad /> },
    { path: "/blogs", element: <Blog /> },
    { path: "/faqs", element: <FAQScreen /> },
    { path: "/privacy-policy/:query", element: <PrivacyPolicy /> },
    { path: "/terms-conditions", element: <TermsConditions /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/press", element: <Press /> },
    { path: "/vacancies", element: <Vacancies /> },
    { path: "/fetching", element: <BlankPage /> },
    { path: "*", element: <NotFound /> },
  ];

  return (
    <Router>
      <Routes>
        {appRoutes.map((route, index) => {
          return (
            <Route key={index} element={route.element} path={route.path} exact>
              {route.path === "/me" && (
                <>
                  <Route path={"bookings"} element={<BookingTabContent />} />
                  <Route path={"favorites"} element={<FavoriteTabContent />} />
                  <Route path={"settings"} element={<SettingsTabContent />} />
                  <Route path={"account"} element={<AccountTabContent />} />
                </>
              )}
            </Route>
          );
        })}
      </Routes>
    </Router>
  );
}

export default AppRoutes;
