import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import Slider from "react-slick";
import Image1 from "../../../assets/images/hotal-1.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faStar} from "@fortawesome/pro-solid-svg-icons";
import BadgeIcon from "../../../assets/images/icons/aroplane-bed.svg";
import {useSelector} from "react-redux";
import {getAppConfig} from "../../../redux/slices/appconfigSlice";
import {getCurrencyBadge} from "../../../helpers/common";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {useParams} from "react-router";

function MapCardSlider({isDesktop}) {
    const {hotelId} = useParams(); // Get the numeric ID from the URL
    const slider = useRef();
    const navigate = useNavigate();
    const appConfig = useSelector(getAppConfig);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const foundOffer = appConfig.offers?.find((f) => f?.id === hotelId);
        const resultArray = foundOffer ? [foundOffer, ...appConfig.offers.filter((f) => f?.id !== hotelId)] : appConfig.offers;

        setPackages(resultArray);
    }, []);

    const settings = {
        className: "center",
        dots: false,
        centerMode: true,
        // infinite: 3,
        centerPadding: "150px",
        slidesToShow: 9,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 200,
        initialSlide: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 3400,
                settings: {
                    slidesToShow: 8,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2900,
                settings: {
                    slidesToShow: 7,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2550,
                settings: {
                    slidesToShow: 6,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2130,
                settings: {
                    slidesToShow: 5,
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 1530,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "200px",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "20px",
                },
            },
        ],
    };

    const ListViewHotels = ({offer}) => {
        return (
            <div className="packages-card-grid">
                <div className="packages-card-header">
                    <img
                        src={offer?.thumbnailUrl || Image1}
                        className="covered-image"
                        alt={"covered-icon"}
                    />
                    <div className="card-badge">
                        <img src={BadgeIcon} alt={"badge-icon"}/>
                        <span>Hotel Incl Flight</span>
                    </div>
                </div>
                <div className="card-middle-area">
                    <div className="packages-card-Body">
                        <div>
                            <h4 className="heading heading-6 dark-text font-poppings ellipis">
                                {offer?.name}
                            </h4>
                            <div className="reviews">
                                <div className="star">
                                    {[1, 2, 3, 4, 5].map((item, index) => {
                                        if (item <= Number(offer?.starRating))
                                            return (
                                                <FontAwesomeIcon
                                                    key={index}
                                                    icon={faStar}
                                                    className="filled"
                                                />
                                            );
                                        else
                                            return (
                                                <FontAwesomeIcon
                                                    key={index}
                                                    icon={faStar}
                                                    className="blank"
                                                />
                                            );
                                    })}
                                    <span>{`${offer?.guestRating || 0} (${
                                        offer?.guestReviewCount || 0
                                    })`}</span>
                                </div>
                            </div>
                            <p className="location">
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                {offer?.address}
                            </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="price-card">
                                {getCurrencyBadge(offer?.perPersonPrice?.currency)}
                                {Number(offer?.perPersonPrice?.totalPrice).toFixed(0)}
                                <span>p.p</span>
                            </div>
                            {isDesktop &&
                                <div className={offer?.id === hotelId ? "selected-btn" : "un-selected-btn"}
                                     onClick={() => {
                                         navigate(`/hotel/${offer?.id}/map`, {
                                             replace: true,
                                         });
                                     }}>
                                    {offer?.id === hotelId
                                        ? "Selected"
                                        : "Select"}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    ListViewHotels.propTypes = {
        offer: PropTypes.object,
    };

    return isDesktop ? (
        packages?.map((offer, index) => (
            <ListViewHotels offer={offer} key={index}/>
        ))
    ) : (
        <div className="mapcards-slider">
            <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldSlide, currentSlide) => {
                    if (packages[currentSlide]?.id) {
                        navigate(`/hotel/${packages[currentSlide]?.id}/map`, {
                            replace: true,
                        });
                    }
                }}
            >
                {packages?.map((offer, index) => (
                    <ListViewHotels offer={offer} key={index}/>
                ))}
            </Slider>
        </div>
    );
}

MapCardSlider.propTypes = {
    isDesktop: PropTypes.bool,
};

export default MapCardSlider;
