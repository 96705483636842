import { get } from "./HttpClient"
import { API_RESET_PASSWORD } from "./ApisRoutes";

export const ResetPasswordService = (email) => {
    const api = API_RESET_PASSWORD+"?eMail="+email;
    return new Promise((resolve, reject) => {
        get(api).then(response => {
            resolve(response?.data);
        }).catch(error => {
            console.log("reset error");
            console.log(error);
            reject(error);
        });
    });
}
