import React from "react";
import AreaCard from "./AreaCard";
import "./style.scss";

const PapularAreas = () => {
  return (
    <section id="papularArea">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title text-center">
              <h2 className="heading heading-1 font-poppings medium">
                Most popular areas in Spain
              </h2>
            </div>
            <div className="papular-area-wrapper">
              <ul>
                <AreaCard />
                <AreaCard />
                <AreaCard />
                <AreaCard />
                <AreaCard />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PapularAreas;
