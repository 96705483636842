import {useEffect} from 'react';

const ScrollLockHOC = ({children, isOpen}) => {
    useEffect(() => {
        const body = document.body;
        if (isOpen) {
            body.classList.add('scroll-lock');
        } else {
            body.classList.remove('scroll-lock');
        }
        return () => {
            body.classList.remove('scroll-lock');
        };
    }, [isOpen]);

    return children;

};

export default ScrollLockHOC;