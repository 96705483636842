import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const Checkbox = (props) => {
  return (
    <div className="form-group custom-checkbox">
      <input type="checkbox" id={props?.id} value={props?.text} checked={props?.checked} onChange={props?.onChange} />
      <label htmlFor={props?.id}>
        {props?.icon && <span className={props.icon}></span>}
        {props?.text}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.string,
}

export default Checkbox;
