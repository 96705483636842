import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import DetailsImage from "../sections/DetailsImage/DetailsImage";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getSearchFilters } from "../../redux/slices/searchFilterSlice";
import { moveNumberToEnd } from "../../helpers/common";
// images
// import PlaneIcon from "../../assets/images/icons/green-plane-up.svg";

function RoomCardModal(props) {
  const { t } = useTranslation();
  const { room } = props;
  const searchfilters = useSelector(getSearchFilters);
  const selectedHotel = searchfilters?.selectedHotel;

  const getBedDescription = () =>
    room?.bedTypeOptions &&
    room?.bedTypeOptions[0]?.description ?
    room?.bedTypeOptions[0]?.description : "";

  return (
    <Modal
      {...props}
      dialogClassName="room-cards-modal"
      size="lg"
      aria-labelledby="room-card-modal"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <DetailsImage images={room?.media} type="room" />

        <h2 className="heading heading-3 medium">{room?.description}</h2>
        <div className="more-info">
          <div className="star">
            <span>{getBedDescription()}</span>
            {[1, 2, 3, 4, 5].map((item, index) => {
              if (item <= Number(searchfilters.selectedHotel?.starRating))
                return (
                  <FontAwesomeIcon
                    key={index}
                    icon={faStar}
                    className="filled"
                  />
                );
              else
                return (
                  <FontAwesomeIcon
                    key={index}
                    icon={faStar}
                    className="blank"
                  />
                );
            })}
            <span>{selectedHotel?.guestRating} {selectedHotel?.guestReviewCount && `(${selectedHotel?.guestReviewCount})`}</span>
          </div>
          <h6>{t("Amenities")}</h6>
          <ul className="list row">
            {room?.amenities?.map((amenity, index) => {
              return (
                <li className="col-sm-6" key={index}>
                  {moveNumberToEnd(amenity?.name)}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}

RoomCardModal.propTypes = {
  room: PropTypes.object,
  onHide: PropTypes.func
};

export default RoomCardModal;
