export const getActualFlightDetails = ({flightSegment}) => {
    const startFlight = flightSegment?.legs[0];
    const lastLegIndex = flightSegment?.legs?.length - 1;
    const endFlight = flightSegment?.legs[lastLegIndex];
    return {startFlight, endFlight};
}

export const getEstimateTimeFormat = estimateTime => {
    let text = (f, s) =>
        estimateTime?.substring(
            estimateTime.indexOf(f) + 1,
            estimateTime?.lastIndexOf(s),
        );
    if (!estimateTime?.includes('H')) {
        return `${text('T', 'M')}m`;
    } else if (!estimateTime?.includes('M')) {
        return `${text('T', 'H')}h`;
    }
    return `${text('T', 'H')}h ${text('H', 'M')}m`;
};

export const getTotalStops = (stops, t) => {
    if (stops === '0') {
        return t('filters:nonStop');
    } else if (stops === '1') {
        return `${stops} ${t('filters:stop')}`;
    } else {
        return `${stops} ${t('filters:stops')}`;
    }
};
