import {get} from './HttpClient';
import {API_GET_FAQ} from './ApisRoutes';

export const FaqService = locale => {
  const faqApi = API_GET_FAQ + '?locale=' + locale;
  return new Promise((resolve, reject) => {
    get(faqApi)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
