import React from "react";
import AppHeader from "../../components/header";
import UserTab from "../../components/UserTab/UserTab";
import Footer from "../../components/footer";
import "./style.scss";

const UserDashborad = () => {
  return (
    <div className="main-wrapper">
      <AppHeader />
      <main className="body-wrapper">
        <div id="user-tabs" className="user-tabs-wrapper">
          <div className="container">
            <UserTab />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default UserDashborad;
