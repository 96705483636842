import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    hotel: null,
    room: null,
    flight: null,
    oldFlight: null,
};

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        resetBookingSliceState: () => initialState,
        setHotelBooking: (state, action) => {
            state.hotel = action.payload;
        },
        setHotelRoom: (state, action) => {
            state.room = action.payload;
        },
        setFlight: (state, action) => {
            state.flight = action.payload;
        },
        setOldFlight: (state, action) => {
            state.oldFlight = action.payload;
        },
        setBothFlight: (state, action) => {
            const payload = {...action.payload};
            state.flight = payload?.newFlight;
            state.oldFlight = payload?.oldFlight;
        },
        resetBookingState: (state) => {
            state.room = null;
            state.flight = null;
            state.oldFlight = null;
            state.hotel = null;
        },
    },
});

export const getBooking = (state) => state.booking;

// Action creators are generated for each case reducer function
export const {
    resetBookingSliceState,
    setHotelBooking,
    setHotelRoom,
    setFlight,
    setBothFlight,
    resetBookingState,
} = bookingSlice.actions;

export default bookingSlice.reducer;
