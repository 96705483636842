import React, {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {PostValidateAuth} from "../../services/LoginService";
import {setIsLogin, setLoginUser} from "../../redux/slices/loginconfigSlice";
import {setActivityIndicator, setModals} from "../../redux/slices/appconfigSlice";
import {postWithoutBody} from "../../services/HttpClient";
import AppConfig from "../../helpers/config";

const AuthComponent = () => {
    const location = useLocation();
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLoginResponse = useCallback(
        async (userObj, provider) => {
            if (userObj?.guidOfUser) {
                dispatch(setActivityIndicator(true));
                const res = await postWithoutBody(
                    `${AppConfig.baseApiURL}/Authenticate/GetAccessToken`,
                );
                await localStorage.setItem("auth_token", res.data.access_token);
                dispatch(setActivityIndicator(false));

                const nameParts = userObj?.userName?.split(' ');
                const loginObject = {
                    email: userObj?.userMail || '',
                    firstname: nameParts?.shift() || '',
                    guid: userObj?.guidOfUser || '',
                    lastname: nameParts?.join(' ') || '',
                    provider,
                };

                dispatch(setLoginUser(loginObject));
                dispatch(setIsLogin(true));
                dispatch(setModals({login: false, register: false, confirmation: false}));
            }
        },
        [dispatch],
    );

    useEffect(() => {
        const handleCallback = async () => {
            const url = window.location.href;
            let params = getParams(url);
            console.log({params});
            // Apple object
            if (params?.token) {
                let tokenObject = JSON.parse(params.token);
                console.log({tokenObject});
                await localStorage.setItem('apple-auth-object', params);
                dispatch(setActivityIndicator(true));
                const userObj = await PostValidateAuth({
                    provider: 'apple',
                    token: tokenObject?.id_token,
                });
                console.log({userObj})
                dispatch(setActivityIndicator(false));

                await handleLoginResponse(userObj, 'apple');
            }
        };

        handleCallback();
    }, [location]);

    function getParams(url) {
        let params = {};
        let parser = new URL(url);
        let queryString = parser.search.substring(1);
        let queries = queryString.split("&");

        queries.forEach((query) => {
            let [key, value] = query.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    }

    return (
        <div>
            <h1>Processing Authentication...</h1>
        </div>
    );
};

export default AuthComponent;
