import React, { useEffect } from "react";
import AppHeader from "../../components/header";
import FAQs from "../../components/sections/FAQs/FAQs";
import Footer from "../../components/footer";
import "./style.scss";

const FAQScreen = () => {
  useEffect(() => window.scroll(0, 0), []);

  return (
    <div className="main-wrapper faq-page">
      <AppHeader />
      <main className="body-wrapper">
        <div style={{ marginTop: "3%" }}>
          <FAQs />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default FAQScreen;
