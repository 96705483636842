import React from "react";
import { useSelector } from "react-redux";
import { getAppConfig } from "../../redux/slices/appconfigSlice";
import HotelItem from "../HotelItem/ListViewCard";
import HotelItemSmall from "../HotelItem/ListViewCardSmall";

import "./style.scss";

const ListView = () => {
  const appconfig = useSelector(getAppConfig);

  return (
    <>
      <div className="list-card-custom-wrapper">
        <div className="row default-list-card">
          {appconfig.offers?.map((offer, index) => {
            return (
              <div
                key={index}
                className={
                  location.pathname.includes("/search/")
                    ? "col-md-12"
                    : "col-md-6"
                }
              >
                <HotelItem offer={offer} index={index} />
              </div>
            );
          })}
        </div>

        <div className="row small-list-card">
          {appconfig.offers?.map((offer, index) => {
            return (
              <div key={index} className="col-md-12">
                <HotelItemSmall offer={offer} index={index} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ListView;
