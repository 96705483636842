import React, { useState, useEffect } from "react";
import TabTitle from "./TabsTitle";
import ListViewCard from "../../HotelItem/ListViewCard";
import ListViewCardSmall from "../../HotelItem/ListViewCardSmall";
import "./style.scss";
import { GetFavoritesService } from "../../../services/GetFavoritesService";
import { useDispatch, useSelector } from "react-redux";
import { getLoginConfig } from "../../../redux/slices/loginconfigSlice";
import {
  getAppConfig,
  setActivityIndicator,
} from "../../../redux/slices/appconfigSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSearchFilters } from "../../../redux/slices/searchFilterSlice";
import "react-swipeable-list/dist/styles.css";

const FavoriteTabContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginconfig = useSelector(getLoginConfig);
  const navigate = useNavigate();
  const searchfilters = useSelector(getSearchFilters);
  const appConfig = useSelector(getAppConfig);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (loginconfig?.loginUser?.guid && loginconfig?.isLogin) {
      GetFavoriteHotels();
    }
  }, [loginconfig?.isLogin, loginconfig?.locale?.code, loginconfig.currency]);

  const GetFavoriteHotels = () => {
    dispatch(setActivityIndicator(true));
    GetFavoritesService({ GUID: loginconfig?.loginUser?.guid })
      .then((response) => {
        dispatch(setActivityIndicator(false));
        setPackages(response.favoritePackages);
      })
      .catch((error) => {
        console.log({ error });
        dispatch(setActivityIndicator(false));
      });
  };

  const routeToSearchScreen = (event) => {
    event.preventDefault();
    const getGuid = () =>
      searchfilters.selectedPackage === null
        ? appConfig.offers[0].guidForBooking
        : searchfilters.selectedPackage.guid;
    navigate(
      "/search/" +
        getGuid() +
        "/query?page=1" +
        "&locale=" +
        loginconfig?.locale?.code +
        "&currency=" +
        loginconfig?.currency?.code
    );
  };

  const deleteFavouritePkg = (index) => {
    if (loginconfig?.loginUser?.guid && loginconfig?.isLogin) {
      setPackages(packages.filter((f, i) => i !== index));
      dispatch(setActivityIndicator(false));
    }
  };

  return (
    <>
      <TabTitle title={t("AppHeader.MyFavourites")}></TabTitle>
      <div className="tabs-inner-content favourites-tab">
        {!packages.length ? (
          <div className="has-no-fav">
            <h6 className="green-text fs-16 font-noto">
              {t("FavoriteTabContent.NothingAddedYet")}
            </h6>
            <p className="white-text fs-16 font-noto">
              {t("FavoriteTabContent.NothingAddedYetDes")}
            </p>
            <a className="destinaotion-action" onClick={routeToSearchScreen}>
              {t("FavoriteTabContent.DiscoverOurDestinations")}
            </a>
          </div>
        ) : (
          <>
            <div className="for-dekstop">
              {packages?.map((_package, index) => {
                return (
                  <ListViewCard
                    key={index}
                    offer={_package}
                    index={index}
                    handler={deleteFavouritePkg}
                  />
                );
              })}
            </div>
            <div className="for-small">
              {packages?.map((_package, index) => {
                return (
                  <ListViewCardSmall
                    key={index}
                    offer={_package}
                    index={index}
                    handler={deleteFavouritePkg}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FavoriteTabContent;
